<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { createPopper, Instance } from '@popperjs/core';

const props = defineProps<{
  element?: HTMLElement;
  title: string;
  readMoreLink?: string;
}>();

const emit = defineEmits<{
  (e: 'dismiss'): void;
}>();

const tooltipElement = ref<HTMLElement | null>(null);
const arrowElement = ref<HTMLElement | null>(null);
let popperInstance: Instance | null = null;

const createPopperInstance = () => {
  if (props.element && tooltipElement.value) {
    popperInstance = createPopper(props.element, tooltipElement.value, {
      placement: 'auto',
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: 'viewport',
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom', 'left', 'right'],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowElement.value,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  }
};

const dismiss = () => {
  emit('dismiss');
};

onMounted(() => {
  createPopperInstance();
});

onBeforeUnmount(() => {
  if (popperInstance) {
    popperInstance.destroy();
    popperInstance = null;
  }
});
</script>

<template>
  <div ref="tooltipElement" class="tooltip-content" @click="dismiss">
    <div
      class="d-flex align-items-center justify-content-between mb-1 clickable"
    >
      <span
        class="fw-medium"
        style="white-space: normal; word-break: break-word"
      >
        {{ title.trim() }}
      </span>
    </div>
    <a
      v-if="readMoreLink"
      :href="readMoreLink"
      class="fw-medium text-white"
      target="_blank"
      style="text-decoration: underline"
    >
      Read more
    </a>

    <div ref="arrowElement" class="tooltip-arrow"></div>
  </div>
</template>
<style scoped>
.tooltip-content {
  background-color: #fc825a;
  box-shadow: 0px 0px 4px 4px rgba(219, 219, 219, 0.125);
  color: #ffffff;
  padding: 12px;
  z-index: 1000;
  max-width: 250px;
  border-radius: 7.5px;
  animation: pulse 3s infinite;
  transition: box-shadow 0.5s ease;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 1px #fc825a5c;
  }
  30% {
    box-shadow: 0px 0px 0px 2px #fc825a5c;
  }
  60% {
    box-shadow: 0px 0px 0px 3px #fc825a5c;
  }
  100% {
    box-shadow: 0px 0px 0px 1px #fc825a5c;
  }
}

.tooltip-content:hover {
  box-shadow: 0px 0px 0px 3px #fc825a5c;
  animation: none !important;
}

.tooltip-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fc825a;
}

[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -5px;
  left: 50% !important;
  transform: translateX(-50%) rotate(45deg) !important;
}

[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -5px;
  left: 50% !important;
  transform: translateX(-50%) rotate(45deg) !important;
}

[data-popper-placement^='left'] > .tooltip-arrow {
  right: -5px;
  top: 50% !important;
  transform: translateY(-50%) rotate(45deg) !important;
}

[data-popper-placement^='right'] > .tooltip-arrow {
  left: -5px;
  top: 50% !important;
  transform: translateY(-50%) rotate(45deg) !important;
}
</style>
