import axios from 'axios';
import { defineStore } from 'pinia';
import { onMounted, ref } from 'vue';

export type FeatureHighlight = {
  id: number;
  title: string;
  selector: string;
  read_more_link: string;
  expire_at: string | null;
};

const useFeatureHighlightStore = defineStore('feature-highlight', () => {
  const isLoading = ref(false);
  const features = ref<FeatureHighlight[]>([]);
  const showAll = ref(false);

  const getFeatures = async () => {
    try {
      isLoading.value = true;

      const { data } = await axios.get('/feature-highlight', {
        params: {
          show_all: showAll.value,
        },
      });
      features.value = data.features;
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  };

  const createFeature = async (feature: Omit<FeatureHighlight, 'id'>) => {
    try {
      isLoading.value = true;

      const { data } = await axios.post('/feature-highlight', feature);
      features.value = [...features.value, data.feature];
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  };

  const updateFeature = async (feature: FeatureHighlight) => {
    try {
      isLoading.value = true;

      const { data } = await axios.put(
        `/feature-highlight/${feature.id}`,
        feature
      );

      features.value = features.value.map((f) =>
        f.id === data.feature.id ? data.feature : f
      );
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  };

  const deleteFeature = async (feature: FeatureHighlight) => {
    try {
      isLoading.value = true;

      await axios.delete(`/feature-highlight/${feature.id}`);
      features.value = features.value.filter((f) => f.id !== feature.id);
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(() => {
    if (features.value.length === 0) {
      getFeatures();
    }
  });

  return {
    features,
    setShowAll: () => (showAll.value = true),
    getFeatures,
    createFeature,
    updateFeature,
    deleteFeature,
  };
});

export default useFeatureHighlightStore;
