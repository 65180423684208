<template>
  <div v-if="filterOptions">
    <h6 class="mb-3">Field Filters</h6>

    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="form-check">
            <input
              type="radio"
              id="showAllFields"
              class="form-check-input"
              value="all-fields"
              v-model="filterOptions.fieldFilterType"
            />
            <label class="form-label" for="showAllFields">
              Show all fields
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
              type="radio"
              id="showOnlyFieldsWithData"
              class="form-check-input"
              value="fields-with-data"
              v-model="filterOptions.fieldFilterType"
            />
            <label class="form-label" for="showOnlyFieldsWithData">
              Show only fields with data
            </label>
          </div>
        </div>
      </div>

      <hr class="mt-2 mb-3" />
    </div>

    <div class="col-12">
      <div class="form-check">
        <input
          type="radio"
          id="showSpecificFields"
          class="form-check-input"
          value="specific-fields"
          v-model="filterOptions.fieldFilterType"
          @change="onChangeShowSpecificFields"
        />
        <label class="form-label" for="showSpecificFields">
          Show only specific fields
        </label>
      </div>
    </div>

    <template v-if="filterOptions.fieldFilterType == 'specific-fields'">
      <div class="col-12">
        <hr />

        <Spinner small v-if="loadingFields" />

        <template v-else>
          <div class="input-group input-group-sm mb-2">
            <span class="input-group-text">
              <i class="fas fa-search" />
            </span>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="`search for a field...`"
              v-model="fieldQuery"
            />
          </div>

          <hr class="my-3" />
          <div class="custom-scrollbar" style="max-height: 250px">
            <div
              class="form-check"
              v-for="(field, fieldKey) in getFields"
              :key="fieldKey"
            >
              <input
                type="checkbox"
                :id="'field-' + fieldKey"
                :value="field.id"
                v-model="filterOptions.specificFieldIds"
                class="form-check-input"
              />
              <label class="form-label" :for="'field-' + fieldKey">
                <span class="fw-medium">{{ field.label }}</span> -
                {{ field.section.label }}
              </label>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Spinner from '@component-library/components/Spinner.vue';
import api from '../api';

export default {
  props: {
    tabId: [Number, String],
    isLoading: Boolean,
    filters: Object,
  },
  data: () => ({
    fields: [],
    loadingFields: false,
    filterOptions: null,
    fieldQuery: null,
  }),
  watch: {
    filterOptions: {
      handler() {
        this.$emit('applyFilters');
      },
      deep: true,
    },
  },
  components: {
    Spinner,
  },
  computed: {
    getFields() {
      if (!this.fieldQuery) {
        return this.fields;
      }

      return this.fields.filter((s) =>
        s.label.toLowerCase().includes(this.fieldQuery.toLowerCase())
      );
    },
  },
  methods: {
    getFilters() {
      return {
        fieldFilterType: this.filterOptions.fieldFilterType,
        specificFieldIds: this.filterOptions.specificFieldIds,
      };
    },
    async getAllAppFields() {
      if (this.fields.length > 0) {
        return;
      }

      try {
        this.loadingFields = true;

        const { data } = await api.getAllAppFields(this.tabId);

        this.fields = Object.freeze(data.fields);
      } catch (e) {
        throw e;
      } finally {
        this.loadingFields = false;
      }
    },
    onChangeShowSpecificFields() {
      if (this.filterOptions.fieldFilterType == 'specific-fields') {
        this.getAllAppFields();
      }
    },
  },
  mounted() {
    this.filterOptions = JSON.parse(JSON.stringify(this.filters));

    if (this.filterOptions.fieldFilterType == 'specific-fields') {
      this.getAllAppFields();
    }
  },
};
</script>
