import Spinner from '@component-library/components/Spinner.vue';
import { Control } from 'ol/control';
import Vue from 'vue';
import { MapType } from '../../../lib/olbm/common/types';

// Vue2's way to imperatively render a component
const SpinnerConstructor = Vue.extend(Spinner);

const LoadingSpinner = (function (Control) {
  /**
   * This control is used to indicate that something is being done on the map.
   * @param {Object} opt_options
   */
  function LoadingSpinner(opt_options) {
    const element = document.createElement('div');
    element.setAttribute('class', 'ol-control p-1 map-spinner');

    Control.call(this, { ...opt_options, element });

    this._renderSpinner();
  }

  if (Control) {
    LoadingSpinner.__proto__ = Control;
  }

  LoadingSpinner.prototype = Object.create(Control && Control.prototype);
  LoadingSpinner.prototype.constructor = LoadingSpinner;
  LoadingSpinner.prototype._renderSpinner = function () {
    let size = 1.2;
    const map = this.getMap();
    if (map && map.getType() !== MapType.PARCEL_EDITOR) {
      size *= map.getViewer().figureLayout.zoom;
    }

    this.element.innerHTML = '';
    const spinner = new SpinnerConstructor({
      propsData: {
        size: `${size}rem`,
      },
    });
    const vm = spinner.$mount();
    this.element.appendChild(vm.$el);
  };
  LoadingSpinner.prototype.refresh = function () {
    this._renderSpinner();
  };

  return LoadingSpinner;
})(Control);

export default LoadingSpinner;
