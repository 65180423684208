<script lang="ts" setup>
// Largely inspired by: vue-chartjs at https://github.com/apertureless/vue-chartjs
import Chart, {
  ChartData,
  ChartDataset,
  ChartTypeRegistry,
  UpdateMode,
} from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  isProxy,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  shallowRef,
  toRaw,
  watch,
  withDefaults,
} from 'vue';

const props = withDefaults(
  defineProps<{
    type: keyof ChartTypeRegistry;
    data: any;
    options?: any;
    plugins?: any;
    datasetIdKey?: string;
    updateMode?: UpdateMode;
    chartName?: string;
  }>(),
  {
    datasetIdKey: 'label',
    updateMode: 'none',
    options: {},
  }
);
const canvasRef = ref<HTMLCanvasElement | null>(null);
const chartRef = shallowRef<Chart | null>(null);

function downloadChart() {
  if (!canvasRef.value) {
    return;
  }
  const link = document.createElement('a');
  link.href = canvasRef.value?.toDataURL('image/png', 1.0);
  link.download = props.chartName
    ? props.chartName
    : props.type + 'chart' + `.png`;
  link.click();
}

defineExpose<{
  downloadChart: () => void;
}>({
  downloadChart,
});

function cloneData(data: ChartData, datasetIdKey: string) {
  const nextData: ChartData = {
    labels: [],
    datasets: [],
  };

  setLabels(nextData, data.labels);
  setDatasets(nextData, data.datasets, datasetIdKey);

  return nextData;
}

function cloneProxy(obj, src = obj) {
  return isProxy(src) ? new Proxy(obj, {}) : obj;
}

function toRawIfProxy(obj) {
  return isProxy(obj) ? toRaw(obj) : obj;
}

function setOptions(chart: Chart, nextOptions) {
  const options = chart.options;

  if (options && nextOptions) {
    Object.assign(options, nextOptions);
  }
}

function setLabels(currentData: ChartData, nextLabels) {
  currentData.labels = nextLabels;
}

function setDatasets(
  currentData: ChartData,
  nextDatasets: ChartDataset[],
  datasetIdKey: string
) {
  const addedDatasets: ChartDataset[] = [];
  if (!nextDatasets) {
    return;
  }
  currentData.datasets = nextDatasets.map((nextDataset) => {
    const currentDataset = currentData.datasets.find(
      (dataset) => dataset[datasetIdKey] === nextDataset[datasetIdKey]
    );

    if (
      !currentDataset ||
      !nextDataset.data ||
      addedDatasets.includes(currentDataset)
    ) {
      return { ...nextDataset } as ChartDataset;
    }

    addedDatasets.push(currentDataset);

    Object.assign(currentDataset, nextDataset);

    return currentDataset;
  });
}
const update = (chart: Chart) => {
  chart.update(props.updateMode ?? 'none');
};

const registerPlugins = () => {
  Chart.register(annotationPlugin);
};

watch(
  [() => props.options, () => props.data],
  ([nextOptionsProxy, nextDataProxy], [prevOptionsProxy, prevDataProxy]) => {
    const chart = toRaw(chartRef.value);

    if (!chart) {
      return;
    }

    let shouldUpdate = false;

    if (nextOptionsProxy) {
      const nextOptions = toRawIfProxy(nextOptionsProxy);
      const prevOptions = toRawIfProxy(prevOptionsProxy);

      if (nextOptions && nextOptions !== prevOptions) {
        setOptions(chart, nextOptions);
        shouldUpdate = true;
      }
    }

    if (nextDataProxy) {
      const nextLabels = toRawIfProxy(nextDataProxy.labels);
      const prevLabels = toRawIfProxy(prevDataProxy.labels);
      const nextDatasets = toRawIfProxy(nextDataProxy.datasets);
      const prevDatasets = toRawIfProxy(prevDataProxy.datasets);

      if (nextLabels !== prevLabels) {
        setLabels(chart.config.data, nextLabels);
        shouldUpdate = true;
      }

      if (nextDatasets && nextDatasets !== prevDatasets) {
        setDatasets(chart.config.data, nextDatasets, props.datasetIdKey);
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      nextTick(() => {
        update(chart);
      });
    }
  },
  { deep: true }
);

const renderChart = () => {
  if (!canvasRef.value) {
    return;
  }
  const clonedData = cloneData(props.data, props.datasetIdKey);
  const proxiedData = cloneProxy(clonedData, props.data);
  chartRef.value = new Chart(canvasRef.value, {
    type: props.type,
    data: proxiedData,
    options: { ...props.options },
    plugins: props.plugins,
  });
};

const destroyChart = () => {
  if (chartRef.value) {
    const chart = toRaw(chartRef.value);
    chart.destroy();
    chartRef.value = null;
  }
};

onMounted(() => {
  registerPlugins();
  renderChart();
});

onUnmounted(destroyChart);
</script>

<template>
    <canvas ref="canvasRef" role="img"></canvas>
</template>
