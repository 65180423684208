<template>
  <nav
    v-if="
      pagination != null && (pagination.last_page > 1 || !pagination.last_page)
    "
    class="d-flex align-items-center justify-content-end"
  >
    <div class="d-flex align-items-center justify-content-center">
      <Spinner v-if="isLoading" small />

      <label v-if="hasTitle" class="me-2 mb-0">
        {{ title }}: page <b>{{ pagination.current_page }}</b> of
        <b>{{ pagination.last_page }}</b>
      </label>

      <ul class="pagination">
        <li class="page-item" v-if="pagination.current_page > 1 && showArrows">
          <a
            class="page-link previous-link"
            href="#"
            @click.prevent.stop="changePage(pagination.current_page - 1)"
          >
            &laquo;
          </a>
        </li>
        <li
          class="page-item"
          v-for="(page, pageKey) in pagesNumber"
          :class="{ active: page == pagination.current_page }"
          :key="pageKey"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent.stop="changePage(page)"
            :class="{ last: getLast(page), first: getFirst(page) }"
          >
            {{ page }}
          </a>
        </li>
        <li
          class="page-item"
          v-if="
            (pagination.current_page < pagination.last_page ||
              (!pagination.last_page &&
                pagination.data &&
                pagination.data.length == pageLimit)) &&
            showArrows
          "
        >
          <a
            class="page-link next-link"
            href="#"
            @click.prevent.stop="changePage(pagination.current_page + 1)"
          >
            &raquo;
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import Spinner from '@component-library/components/Spinner.vue';

export default {
  props: {
    pagination: {
      required: true,
    },
    offset: {
      type: Number,
      default: 4,
    },
    title: {
      type: String,
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
  },
  components: { Spinner },
  computed: {
    pagesNumber() {
      if (!this.pagination.last_page) {
        return [];
      }
      if (this.isDataBelowLimit) {
        let paginationArray = [];
        for (let i = 0; i < this.pagination.last_page; i++) {
          paginationArray.push(i + 1);
        }
        return paginationArray;
      }

      let currentPage = this.pagination.current_page;
      let lastPage = this.pagination.last_page;
      let visiblePagesCount = this.pageLimit;
      let visiblePagesThreshold = Math.round((visiblePagesCount - 1) / 2);
      let pagintationTriggersArray = Array(this.pageLimit - 1).fill(0);

      if (currentPage <= visiblePagesThreshold + 1) {
        pagintationTriggersArray[0] = 1;

        let pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return pagintationTriggersArray[0] + index;
          }
        );

        pagintationTriggers.push(lastPage);

        return pagintationTriggers;
      }

      if (currentPage >= lastPage - visiblePagesThreshold + 1) {
        let pagintationTriggers = pagintationTriggersArray.map(
          (paginationTrigger, index) => {
            return lastPage - index;
          }
        );

        pagintationTriggers.reverse().unshift(1);

        return pagintationTriggers;
      }

      pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1;

      let pagintationTriggers = pagintationTriggersArray.map(
        (paginationTrigger, index) => {
          return pagintationTriggersArray[0] + index;
        }
      );

      pagintationTriggers.unshift(1);
      pagintationTriggers[pagintationTriggers.length - 1] = lastPage;

      return pagintationTriggers;
    },
    isDataBelowLimit() {
      return this.pagination.last_page < this.pageLimit;
    },
    getHalfwayLimit() {
      return this.pageLimit - Math.round(this.pageLimit / 2);
    },
  },
  methods: {
    changePage(page) {
      if (page == this.pagination.current_page) {
        return;
      }
      this.pagination.current_page = page;
      this.$emit('paginate', page);
    },
    getFirst(page) {
      if (this.isDataBelowLimit) return false;

      return (
        page == 1 &&
        Math.abs(page - this.pagination.current_page) > this.getHalfwayLimit
      );
    },
    getLast(page) {
      if (this.isDataBelowLimit) return false;

      return (
        page == this.pagination.last_page &&
        Math.abs(page - this.pagination.current_page) > this.getHalfwayLimit
      );
    },
  },
};
</script>
<style scoped>
a.first::after {
  content: '...';
}

a.last::before {
  content: '...';
}

.pagination {
  margin: 0em;
  flex-wrap: wrap;
}

.page-item {
  margin-right: 5px;
}

.page-item:last-child {
  margin-right: 0px;
}

.page-link {
  border-radius: 3px;
  padding: 0.5rem;
  height: 2rem;
  min-width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #6c757d;
  font-size: inherit;
}

.page-item.active .page-link {
  color: #ffffff !important;
}

.previous-link,
.next-link {
  background: #f0f3ff;
}
</style>
