<script lang="ts" setup>
import useChatProvider from '@component-library/chat-service';
import ConfirmModal from '@component-library/components/ConfirmModal.vue';
import Toasts from '@component-library/components/Toasts.vue';
import useLegacyStore from '@component-library/composables/useLegacyStore';
import { useAIExplorerStore } from '@component-library/store/ai-explorer';
import { useDialogStore } from '@component-library/store/dialog';
import { useProjectStore } from '@component-library/store/project';
import { useToastStore } from '@component-library/store/toasts';
import type { User } from '@component-library/user';
import moment from 'moment';
import { defineAsyncComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import useAuth from '../composables/useAuth';
import FeatureHighlighter from '@component-library/components/FeatureHighlighter.vue';
import SubscriptionsModal from '@component-library/components/SubscriptionsModal.vue';

const AIExplorerContainer = defineAsyncComponent(
  () => import('@/js/modules/ai-explorer/index.vue')
);

const dialogStore = useDialogStore();
const projectStore = useProjectStore();
const legacyStore = useLegacyStore();
const toastStore = useToastStore();
const chatProvider = useChatProvider();
const router = useRouter();
const auth = useAuth();
projectStore.setLegacyStore(legacyStore);

const cachedViews = ref<string[]>([]);

const aiExplorerStore = ref();

watch(
  () => auth.user(),
  (user) => {
    if (user && !aiExplorerStore.value) {
      aiExplorerStore.value = useAIExplorerStore();
      aiExplorerStore.value.setLegacyStore(legacyStore);
      aiExplorerStore.value.setUser(user);
    }
  }
);

onMounted(chatProvider.loadScript);

watch(
  () => {
    return router.currentRoute;
  },
  (to) => {
    if (to.name == 'project_pathway') {
      cachedViews.value = [];
    }

    if (to.name == 'project_dashboard') {
      cachedViews.value = ['ProjectLayout'];
    }

    const user = auth.user() as User;
    if (!user) {
      return;
    }

    if (to.name != 'account-company' && user.company) {
      if (!user.company.subscription_auto_lockout) {
        return;
      }

      const subscriptionUntil = moment(user.company.subscription_until);

      if (subscriptionUntil.isBefore(moment().subtract(1, 'day'))) {
        toastStore.error(
          'Your subscription has expired. Please upgrade your plan or contact us.'
        );

        if (user.onboarding_complete) {
          router.replace({ name: 'account-company' });
        }
      } else if (
        user.company.subscription_is_trial &&
        subscriptionUntil.isBefore(moment())
      ) {
        toastStore.warning(
          'Your subscription expires today, you will be locked out tomorrow. Please contact us'
        );
      }
    }
  }
);
</script>

<template>
  <div style="display: contents">
    <Toasts />
    <div class="d-flex flex-column flex-fill h-100">
      <div
        :class="[
          {
            'condensed-window': aiExplorerStore?.showAIExplorerRegularMode,
            'd-flex flex-fill flex-column':
              !aiExplorerStore?.showAIExplorerRegularMode,
          },
        ]"
      >
        <KeepAlive :include="cachedViews">
          <RouterView v-if="auth.ready()" :key="$route.fullPath"></RouterView>
        </KeepAlive>
      </div>
      <div
        v-if="auth.check() && aiExplorerStore?.showAIExplorerRegularMode"
        class="ai-chat-window"
      >
        <AIExplorerContainer />
      </div>
    </div>

    <ConfirmModal
      v-if="dialogStore.showModal"
      :show="dialogStore.showModal"
      :title="dialogStore.title"
      :message="dialogStore.message"
      :validationString="dialogStore.validationString"
      :danger="dialogStore.danger"
      :options="dialogStore.options"
      @confirm="dialogStore.accepted"
      @cancel="dialogStore.rejected"
    />

    <template v-if="auth.check()">
      <FeatureHighlighter />
      <SubscriptionsModal />
    </template>
  </div>
</template>

<style scoped>
.condensed-window {
  margin-right: 33%;
}
.ai-chat-window {
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.3s;
  margin-top: 56px;
  width: 33%;
  height: calc(100vh - 56px);
  z-index: 1001;
}
</style>
