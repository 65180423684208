<template>
  <div class="dashboard-item h-100" :class="localLoading ? 'loading' : 'ready'">
    <div class="w-100 h-100 d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div v-if="!hideControls" class="d-flex align-items-center">
          <div class="d-inline-block d-flex gap-3">
            <button
              v-if="!readOnly && eventSourceButtonVisible"
              class="btn btn-sm"
              :class="eventSourceButtonExtraClass"
              :title="`Event Source is ${
                eventSourceEnabled ? 'enabled' : 'disabled'
              }.`"
              @click="onToggleEventSource"
            >
              <i class="fad fa-bolt fa-fw"></i>
            </button>

            <InputSelect
              v-if="dashboardItemGroups.length > 1"
              label="Select Group"
              small
              class="me-2 mb-2"
              v-model="selectedGroupIndex"
            >
              <option
                :value="index"
                :key="index"
                v-for="(group, index) of dashboardItemGroups"
              >
                {{ group.title }}
              </option>
            </InputSelect>

            <InputSelect
              v-if="
                dashboardItemGroupPages && dashboardItemGroupPages.length > 1
              "
              label="Select Page"
              small
              class="me-3"
              v-model="selectedPageIndex"
            >
              <option
                :value="index"
                :key="index"
                v-for="(page, index) of dashboardItemGroupPages"
              >
                {{ index + 1 }}
              </option>
            </InputSelect>
          </div>

          <InteractiveRelationshipDropdown
            v-if="interactiveRelationshipDropdownVisible"
            class="d-inline-block"
            :class="{ 'ms-2': eventSourceButtonVisible }"
            :eventSources="eventSources"
            @toggleInteractiveRelationship="onToggleInteractiveRelationship"
          />
        </div>
        <div class="d-flex flex-column justify-content-center">
          <div class="btn-group" v-if="!readOnly">
            <button
              v-if="!isBeingEdited"
              class="btn btn-sm btn-outline-secondary"
              title="Edit"
              @click="onClickEdit"
            >
              <i class="fal fa-pencil-alt"></i>
            </button>

            <button
              class="btn btn-sm btn-outline-secondary"
              title="Export"
              :disabled="dashboardItemStatuses.loading"
              @click="exportGraph"
            >
              <i class="fal fa-download"></i>
            </button>

            <button
              class="btn btn-sm btn-outline-secondary"
              title="Refresh"
              :disabled="dashboardItemStatuses.loading"
              @click="refresh"
            >
              <i class="fal fa-redo"></i>
            </button>

            <button
              v-if="!isBeingEdited"
              class="btn btn-sm btn-outline-danger"
              title="Delete"
              @click="onClickDelete"
            >
              <i class="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>

      <div
        class="position-relative w-100 flex-grow-1"
        :style="contentPaneStyle"
        ref="contentPane"
      >
        <div
          v-if="!config"
          class="w-100 h-100 d-flex flex-column align-items-center justify-content-center text-center"
        >
          <h1 :class="toolIcon" />
          <h6 class="fw-bolder w-75">
            Use Info and Style on the sidebar to configure the item!
          </h6>
          <small class="text-muted mb-0">
            It appears that no data is available for the current configuration.
          </small>
        </div>
        <component v-else :is="config.component" v-bind="config.props" />

        <button
          v-if="dashboardItem?.options?.info?.eventSourceEnabled"
          class="btn btn-outline-info border-0 position-absolute interactive-relationship-button"
          @click="
            dashboardItem && $emit('makeObserversBreathe', dashboardItem.id)
          "
        >
          <i class="fad fa-bolt" title="Event Source"></i>
        </button>

        <button
          v-if="
            observerSupported &&
            (dashboardItem?.options?.info?.eventSourceIds?.length || 0) > 0
          "
          class="btn btn-outline-info border-0 position-absolute interactive-relationship-button"
          @click="$emit('makeEventSourcesBreathe', dashboardItem?.id || -1)"
        >
          <i class="fab fa-staylinked" title="Observer"></i>
        </button>
      </div>
    </div>

    <Spinner
      small
      v-if="dashboardItemStatuses.loading"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -0.5rem;
        margin-left: -0.5rem;
      "
    />

    <div
      v-if="dashboardItemStatuses.breathing"
      class="w-100 h-100 position-absolute breathing-pane"
      :style="{ opacity: breathingPaneOpacity }"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import _isEqual from 'lodash/isEqual';
import _debounce from 'lodash/debounce';
import createConfig from './createConfig';
import InteractiveRelationshipDropdown from './InteractiveRelationshipDropdown.vue';
import {
  getLoadDataParams,
  getUnusedSwatches,
  loadData as utilLoadData,
} from '../../utils';
import * as TOOL_TYPES from '../../constants/toolTypes';
import * as VIEW_MODES from '../../constants/viewModes';
import InputSelect from '@component-library/components/InputSelect.vue';
import { computed, nextTick, ref, watch } from 'vue';
import { useInsightsStore } from '../../store/insights';
import { useToastStore } from '@component-library/store/toasts';
import { useNavigationStore } from '@component-library/store/navigation';
import axios from 'axios';
import Spinner from '@component-library/components/Spinner.vue';

const navigationStore = useNavigationStore();
const toastStore = useToastStore();
const props = withDefaults(
  defineProps<{
    dashboardId: number;
    dashboardItemId: number;
    readOnly?: boolean;
    hideControls?: boolean;
    animate?: boolean;
  }>(),
  { animate: true }
);
const emit = defineEmits<{
  (type: 'edit', id: number): void;
  (type: 'delete', id: number): void;
  (type: 'makeObserversBreathe', id: number): void;
  (type: 'makeEventSourcesBreathe', id: number): void;
  (type: 'changeSelection', id: number): void;
  (type: 'changeBackgroundColor', id: number): void;
}>();

defineExpose({ selectPage, refresh });
const insightsStore = useInsightsStore();
const refreshDebounced = _debounce(refresh, 100);
const contentPane = ref<HTMLElement>();
const breathingTimerId = ref<NodeJS.Timer | null>(null);
const breathingPaneOpacity = ref(1);
const selectedGroupIndex = ref(0);
const selectedPageIndex = ref(0);
const localLoading = ref(false);

function selectPage(group: number, page: number) {
  selectedGroupIndex.value = group;
  selectedPageIndex.value = page;
}

const dashboard = computed(() => {
  return insightsStore.findDashboardById(props.dashboardId);
});

const dashboardItem = computed(() => {
  return insightsStore.findDashboardItemById(
    props.dashboardId,
    props.dashboardItemId
  );
});

const dashboardItemStatuses = computed(() => {
  return insightsStore.getDashboardItemStatuses(
    props.dashboardId,
    props.dashboardItemId
  );
});

const isBeingEdited = computed(() => {
  return insightsStore.isDashboardItemBeingEdited(props.dashboardItemId);
});

const toolIcon = computed(() => {
  const toolType = dashboardItem.value?.toolType;
  if (!toolType) {
    return undefined;
  }
  const { icon } = insightsStore.findTool(toolType) ?? {};
  return icon;
});

const contentPaneStyle = computed(() => {
  const { backgroundColor, borderColor } =
    dashboardItem.value?.options?.style?.canvas ?? {};
  return {
    'background-color': backgroundColor,
    border: borderColor ? `1px solid ${borderColor}` : '',
    height: !props.readOnly ? 'calc(100% - 6.25rem)' : '100%',
  };
});

const isBarOrPieChart = computed(() => {
  const toolType = dashboardItem.value?.toolType;
  if (!toolType) {
    return false;
  }
  return [TOOL_TYPES.BAR_CHART, TOOL_TYPES.PIE_CHART].indexOf(toolType) !== -1;
});

const eventSourceSupported = computed(() => {
  const toolType = dashboardItem.value?.toolType;
  if (!toolType) {
    return false;
  }
  return (
    [
      TOOL_TYPES.BAR_CHART,
      TOOL_TYPES.PIE_CHART,
      TOOL_TYPES.LINE_CHART,
      TOOL_TYPES.SCATTER_CHART,
      TOOL_TYPES.WORD_CLOUD_CHART,
    ].indexOf(toolType) !== -1
  );
});

const dataParams = computed(() => {
  const { toolType, options } = dashboardItem.value ?? {};
  return getLoadDataParams(toolType, options);
});

const config = computed(() => {
  const { toolType, data, options } = dashboardItem.value ?? {};
  if (!data) {
    return null;
  }

  const globalOptions = {
    highlightColor: insightsStore.highlightColor,
    groupIndex: selectedGroupIndex.value,
    pageIndex: selectedPageIndex.value,
  };
  let config: any = createConfig(
    toolType,
    data,
    options,
    globalOptions,
    props.dashboardItemId
  );

  if (!eventSourceSupported.value || !options?.info?.eventSourceEnabled) {
    return config;
  }

  if (options.info.viewModeId === VIEW_MODES.CHART.id) {
    config = {
      ...config,
      props: {
        ...config.props,
        options: {
          ...config.props.options,
          onHover: function (event, chartElements) {
            if (contentPane.value) {
              contentPane.value.style.cursor =
                chartElements.length > 0 ? 'pointer' : 'auto';
            }
          },
          onClick: function (event, chartElements) {
            switch (toolType) {
              case TOOL_TYPES.BAR_CHART:
                onClickBarElement(event, chartElements);
                break;
              case TOOL_TYPES.PIE_CHART:
                onClickPieElement(event, chartElements);
                break;
            }
          },
        },
      },
    };
    config.props.options.animation = props.animate;
  } else if (options.info.viewModeId === VIEW_MODES.NUMBERS.id) {
    config = {
      ...config,
      props: {
        ...config.props,
        handleClick: function (index) {
          updateEventSourceSelection(index);
        },
      },
    };
  }

  return config;
});

const dashboardItemGroups = computed(() => {
  if (!Array.isArray(dashboardItem.value?.data)) {
    return [];
  }
  return dashboardItem.value?.data as any[];
});

const dashboardItemGroupPages = computed(() => {
  if (
    !dashboardItem.value?.data ||
    !Array.isArray(dashboardItem.value.data) ||
    !(selectedGroupIndex.value in dashboardItem.value.data) ||
    !dashboardItem.value.data[selectedGroupIndex.value]?.pages
  ) {
    return [];
  }
  return dashboardItem.value.data[selectedGroupIndex.value].pages;
});

const eventSourceEnabled = computed(() => {
  return dashboardItem.value?.options?.info?.eventSourceEnabled;
});

const eventSourceButtonVisible = computed(() => {
  return eventSourceSupported.value && isBeingEdited.value;
});

const eventSourceButtonExtraClass = computed(() => {
  return eventSourceEnabled.value ? 'btn-dark' : 'btn-light';
});

const eventSourceSelection = computed(() => {
  const { toolType } = dashboardItem.value ?? {};
  if (toolType === TOOL_TYPES.BAR_CHART) {
    return dashboardItem.value?.options?.info?.selection[0];
  } else if (toolType === TOOL_TYPES.PIE_CHART) {
    return dashboardItem.value?.options?.info?.selection;
  }

  return [];
});

const eventSourceBackgroundColor = computed(() => {
  if (
    dashboardItem.value?.options?.style?.graph &&
    Array.isArray(dashboardItem.value.options.style.graph) &&
    dashboardItem.value.options.style.graph.length > 0
  ) {
    return dashboardItem.value.options.style.graph[0].backgroundColor;
  }
  return undefined;
});

const observerSupported = computed(() => {
  const toolType = dashboardItem.value?.toolType;
  if (!toolType) {
    return false;
  }
  return (
    [
      TOOL_TYPES.BAR_CHART,
      TOOL_TYPES.PIE_CHART,
      TOOL_TYPES.LINE_CHART,
      TOOL_TYPES.SCATTER_CHART,
      TOOL_TYPES.WORD_CLOUD_CHART,
    ].indexOf(toolType) !== -1
  );
});

const interactiveRelationshipDropdownVisible = computed(() => {
  return (
    observerSupported.value && isBeingEdited.value && !eventSourceEnabled.value
  );
});

const eventSources = computed(() => {
  const { templateId, sectionId, eventSourceIds } =
    dashboardItem.value?.options?.info ?? {};

  if (!dashboard.value?.items) {
    return [];
  }

  return dashboard.value.items
    .filter((item) => {
      return (
        item.id !== dashboardItem.value?.id &&
        item.options?.info?.templateId === templateId &&
        item.options?.info?.sectionId === sectionId &&
        item.options?.info?.eventSourceEnabled
      );
    })
    .map((item) => {
      return {
        id: item.id,
        title: item.options?.info?.title || '',
        checked: eventSourceIds?.indexOf(item.id) !== -1,
      };
    });
});

const shouldUseBackgroundColorFromEventSource = computed(() => {
  if (isBarOrPieChart.value) {
    const { dimensionFieldId, eventSourceIds } =
      dashboardItem.value?.options?.info ?? {};

    // If this dashboard item observes only one event source and it has the same
    // dimension field as of the event source, then it uses the colors from the
    // background color of the event source.
    if (eventSourceIds?.length === 1) {
      const [eventSourceId] = eventSourceIds;
      const eventSource = dashboard.value?.items.find(
        (item) => item.id === eventSourceId
      );
      if (!eventSource) {
        return false;
      }

      if (dimensionFieldId === eventSource.options?.info?.dimensionFieldId) {
        return true;
      }
    }
  }

  return false;
});

const labelsForBackgroundColor = computed(() => {
  return dashboardItem.value?.data?.labels ?? [];
});

const labelsForEventSourceSelection = computed(() => {
  return dashboardItem.value?.data?.labels ?? [];
});

function setData(data: any) {
  insightsStore.updateDashboardItem({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: { data },
  });
}

function setLoading(loading: boolean) {
  localLoading.value = loading;
  insightsStore.updateDashboardRuntimeData({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: { loading },
  });
}

async function loadData() {
  try {
    setLoading(true);
    const params = { ...dataParams.value };
    if (!dashboardItem.value?.toolType) {
      throw new Error('Tool type is not defined');
    }
    return await utilLoadData(dashboardItem.value.toolType, params);
  } catch (e: any) {
    if (e.response?.status >= 400 && e.response?.status < 500) {
      console.error(e);
      return;
    } else {
      toastStore.error(
        'Something went wrong when loading the graph, please try again later.'
      );
    }
    throw e;
  } finally {
    setLoading(false);
  }
}

async function refresh() {
  const data = await loadData();
  setData(data);
  // this.setData causes change of state so $nextTick is required.
  await nextTick(async () => {
    // Use ? here because this compoent could have been deleted when the request is finished.
    if (dashboardItem.value?.id && !isBeingEdited.value) {
      const hasEventSourceBeingEdited =
        dashboardItem.value?.options?.info?.eventSourceIds?.some(
          (eventSourceId) =>
            insightsStore.isDashboardItemBeingEdited(eventSourceId)
        );
      if (hasEventSourceBeingEdited) {
        // The event source is responsible for saving this item.
        return;
      }

      await save();
    }
  });
}

async function save() {
  try {
    setLoading(true);
    await insightsStore.saveDashboardItem({
      dashboardId: props.dashboardId,
      dashboardItemId: props.dashboardItemId,
    });
  } finally {
    setLoading(false);
  }
}

function onClickEdit() {
  emit('edit', props.dashboardItemId);
}

function onClickDelete() {
  emit('delete', props.dashboardItemId);
}

async function exportGraph() {
  if (import.meta.env.DEV) {
    alert('cannot export in dev mode, run vite build to test export.');
    return;
  }
  try {
    await axios.post(
      '/data-insights/dashboard-item/' + props.dashboardItemId + '/export',
      { width: 1600, height: 900 }
    );

    navigationStore.openNotificationDropdown();
  } catch (err) {
    toastStore.unexpected();
    throw err;
  }
}

function onToggleEventSource() {
  const info = dashboardItem.value?.options?.info ?? {};
  const { eventSourceEnabled } = info;

  insightsStore.updateDashboardItemOptions({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: {
      info: {
        ...dashboardItem.value?.options?.info,
        eventSourceEnabled: !eventSourceEnabled,
      },
    },
  });
  insightsStore.updateDashboardRuntimeData({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: { clearSelection: true, clearEventSourceIds: true },
  });

  const observers = insightsStore.getObservers(
    props.dashboardId,
    props.dashboardItemId
  );
  observers.forEach((observer) => {
    insightsStore.updateDashboardRuntimeData({
      dashboardId: props.dashboardId,
      dashboardItemId: observer.id,
      update: {
        eventSourceIdToToggle: props.dashboardItemId,
      },
    });
  });
}

function updateEventSourceSelection(clickedElementIndex: number) {
  const { info } = dashboardItem.value?.options ?? {};
  const { selection } = info ?? {};

  let selectedElementIndices: number[] = [];
  if (dashboardItem.value?.toolType === TOOL_TYPES.BAR_CHART) {
    selectedElementIndices = selection[0];
  } else if (dashboardItem.value?.toolType === TOOL_TYPES.PIE_CHART) {
    selectedElementIndices = selection;
  }

  const nextSelectedElementIndices: number[] = [...selectedElementIndices];
  const index = selectedElementIndices.indexOf(clickedElementIndex);
  if (index === -1) {
    nextSelectedElementIndices.push(clickedElementIndex);
  } else {
    nextSelectedElementIndices.splice(index, 1);
  }
  nextSelectedElementIndices.sort((item1, item2) => item1 - item2);

  let nextSelection: number[] | number[][] = nextSelectedElementIndices;
  if (dashboardItem.value?.toolType === TOOL_TYPES.BAR_CHART) {
    nextSelection = [nextSelectedElementIndices];
  }
  insightsStore.updateDashboardItemOptions({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: {
      info: { ...info, selection: nextSelection },
    },
  });
}

function onClickBarElement(event: Event, barElements) {
  if (barElements.length === 0) {
    return;
  }

  const [{ _index: clickedElementIndex }] = barElements;
  updateEventSourceSelection(clickedElementIndex);
}

function onClickPieElement(event: Event, pieElements) {
  if (pieElements.length === 0) {
    return;
  }

  const [{ _index: clickedElementIndex }] = pieElements;
  updateEventSourceSelection(clickedElementIndex);
}

function onToggleInteractiveRelationship(eventSourceIdToToggle: number) {
  insightsStore.updateDashboardRuntimeData({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: { eventSourceIdToToggle },
  });
}

function useBackgroundColorFromEventSource() {
  const info = dashboardItem.value?.options?.info ?? {};
  const { eventSourceIds } = info;
  const eventSourceId = eventSourceIds?.at(-1);
  const eventSource = dashboard.value?.items.find(
    (item) => item.id === eventSourceId
  );
  const eventSourceGraph = eventSource?.options?.style?.graph ?? [];
  const style = dashboardItem.value?.options?.style ?? {};
  const graph = dashboardItem.value?.options?.style?.graph ?? [];
  let backgroundColor = graph[0]?.backgroundColor || '';

  const eventSourceBackgroundColor = eventSourceGraph[0]?.backgroundColor || '';

  backgroundColor = labelsForBackgroundColor.value.reduce((accu, item) => {
    return {
      ...accu,
      [item]: eventSourceBackgroundColor[item],
    };
  }, backgroundColor);

  insightsStore.updateDashboardItemOptions({
    dashboardId: props.dashboardId,
    dashboardItemId: props.dashboardItemId,
    update: {
      style: {
        ...style,
        graph: [
          {
            ...graph[0],
            backgroundColor,
          },
        ],
      },
    },
  });
}

watch(
  () => dataParams.value,
  async (newLoadDataParams, oldLoadDataParams) => {
    if (!_isEqual(newLoadDataParams, oldLoadDataParams)) {
      await refreshDebounced();
    }
  }
);

watch(
  () => shouldUseBackgroundColorFromEventSource.value,
  (newShould, oldShould) => {
    if (newShould && !oldShould) {
      useBackgroundColorFromEventSource();
    }
  }
);

watch(
  () => labelsForBackgroundColor.value,
  (newLabels, oldLabels) => {
    if (!_isEqual(newLabels, oldLabels)) {
      if (shouldUseBackgroundColorFromEventSource.value) {
        useBackgroundColorFromEventSource();
      } else {
        const style = dashboardItem.value?.options?.style;
        let backgroundColor = Array.isArray(style?.graph)
          ? style?.graph[0]?.backgroundColor || ''
          : '';
        const usedSwatches = newLabels
          .map((item) => {
            return backgroundColor[item];
          })
          .filter((item) => !!item);
        const unusedSwatchesCount = newLabels.length - usedSwatches.length;
        const unusedSwatches = getUnusedSwatches(
          usedSwatches,
          unusedSwatchesCount
        );

        let unusedSwatchCounter = 0;
        backgroundColor = newLabels.reduce((accu, item) => {
          if (!accu[item]) {
            accu = {
              ...accu,
              [item]: unusedSwatches[unusedSwatchCounter++],
            };
          }
          return accu;
        }, backgroundColor);

        insightsStore.updateDashboardItemOptions({
          dashboardId: props.dashboardId,
          dashboardItemId: props.dashboardItemId,
          update: {
            style: {
              ...style,
              graph: [
                {
                  ...(Array.isArray(style?.graph)
                    ? style?.graph[0]
                    : undefined),
                  backgroundColor,
                },
              ],
            },
          },
        });
      }
    }
  }
);

watch(
  () => labelsForEventSourceSelection.value,
  (newValue, oldValue) => {
    if (!_isEqual(newValue, oldValue)) {
      const selectedLabels = oldValue.filter(
        (item, index) => eventSourceSelection.value?.indexOf(index) !== -1
      );
      const nextSelectedElementIndices = selectedLabels.map((item) =>
        newValue.indexOf(item)
      );
      let nextSelection = nextSelectedElementIndices;
      if (dashboardItem.value?.toolType === TOOL_TYPES.BAR_CHART) {
        nextSelection = [nextSelectedElementIndices];
      }
      insightsStore.updateDashboardItemOptions({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: {
          info: {
            ...dashboardItem.value?.options?.info,
            selection: nextSelection,
          },
        },
      });
    }
  }
);

watch(
  () => eventSourceSelection.value,
  async (newSelection, oldSelection) => {
    if (!_isEqual(newSelection, oldSelection)) {
      // Save is required because the selection is used by the query in the backend.
      await save();
      emit('changeSelection', props.dashboardItemId);
    }
  }
);

watch(
  () => eventSourceBackgroundColor.value,
  (newBackgroundColor, oldBackgroundColor) => {
    if (!_isEqual(newBackgroundColor, oldBackgroundColor)) {
      emit('changeBackgroundColor', props.dashboardItemId);
    }
  }
);

watch(
  () => dashboardItemStatuses.value.breathing,
  (newBreathing) => {
    if (!!breathingTimerId.value) {
      clearInterval(breathingTimerId.value);
      breathingTimerId.value = null;
    }

    if (newBreathing) {
      let delta = -0.1;
      let opacity = 1;
      let breathingTimes = 0;
      breathingTimerId.value = setInterval(() => {
        opacity += delta;
        if (opacity <= 0 || opacity >= 1) {
          delta = -delta;
          breathingTimes++;
        }
        breathingPaneOpacity.value = opacity;

        if (breathingTimes === 2) {
          insightsStore.updateDashboardRuntimeData({
            dashboardId: props.dashboardId,
            dashboardItemId: props.dashboardItemId,
            update: { breathing: false },
          });
        }
      }, 200);
    }
  }
);

watch(
  () => dashboardItemStatuses.value.hasOutdatedData,
  async (newHasOutdatedData) => {
    if (newHasOutdatedData) {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { hasOutdatedData: false, loading: true },
      });
      await refreshDebounced();
    }
  }
);

watch(
  () => dashboardItemStatuses.value.checkBackgroundColor,
  (newCheckBackgroundColor) => {
    if (newCheckBackgroundColor) {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { checkBackgroundColor: false },
      });

      if (shouldUseBackgroundColorFromEventSource.value) {
        useBackgroundColorFromEventSource();
      }
    }
  }
);

watch(
  () => dashboardItemStatuses.value.clearSelection,
  (newClearSelection) => {
    if (newClearSelection) {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { clearSelection: false },
      });

      const toolType = dashboardItem.value?.toolType;
      const info = dashboardItem.value?.options?.info;

      insightsStore.updateDashboardItemOptions({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: {
          info: {
            ...info,
            selection: toolType === TOOL_TYPES.BAR_CHART ? [[]] : [],
          },
        },
      });
    }
  }
);

watch(
  () => dashboardItemStatuses.value.eventSourceIdToToggle,
  (newEventSourceIdToToggle) => {
    if (newEventSourceIdToToggle) {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { eventSourceIdToToggle: null },
      });

      const info = dashboardItem.value?.options?.info;
      const eventSourceIds = info?.eventSourceIds ?? [];

      const nextEventSourceIds = [...eventSourceIds];
      const index = eventSourceIds.indexOf(newEventSourceIdToToggle);
      if (index === -1) {
        nextEventSourceIds.push(newEventSourceIdToToggle);
      } else {
        nextEventSourceIds.splice(index, 1);
      }

      insightsStore.updateDashboardItemOptions({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { info: { ...info, eventSourceIds: nextEventSourceIds } },
      });
    }
  }
);

watch(
  () => dashboardItemStatuses.value.clearEventSourceIds,
  (newClearEventSourceIds) => {
    if (newClearEventSourceIds) {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { clearEventSourceIds: false },
      });

      const info = dashboardItem.value?.options?.info;
      insightsStore.updateDashboardItemOptions({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: {
          info: {
            ...info,
            eventSourceIds: [],
          },
        },
      });
    }
  }
);

watch(
  () => selectedGroupIndex.value,
  (newIndex, oldIndex) => {
    if (newIndex !== oldIndex) {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.dashboardId,
        dashboardItemId: props.dashboardItemId,
        update: { selectedGroupIndex: newIndex },
      });
    }
  }
);
</script>

<style lang="scss" scoped>
.dashboard-item {
  .interactive-relationship-button {
    left: 2px;
    bottom: 2px;
  }

  .breathing-pane {
    top: 0;
    left: 0;
    border: 4px solid #ff0000;
  }
}
</style>
