export async function loadChemicals(matrix) {
  const {
    data: { chemicals },
  } = await axios.get('/figure8/data/chemicals', {
    params: { matrix },
  });
  chemicals.sort((item1, item2) =>
    item1.display_title.localeCompare(item2.display_title)
  );
  return chemicals;
}

export async function loadChemicalResults(chemicalIds, matrix) {
  const result = [];
  const pageSize = 50;
  const total = chemicalIds.length;
  const pageCount = Math.ceil(total / pageSize);
  for (let i = 0; i < pageCount; i++) {
    const chemical_ids = chemicalIds.slice(
      i * pageSize,
      Math.min((i + 1) * pageSize, total)
    );
    const {
      data: { chemicals: chemicalResults },
    } = await axios.get('/figure8/data/chemical-results', {
      params: {
        chemical_ids,
        matrix,
      },
    });
    result.push(...chemicalResults);
  }

  return result;
}
