import store from '@/js/store';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { getStoreApi } from '../../../lib/olbm/common/store-api';
import { default as olbmCreateSampleLayer } from '../../../lib/olbm/layer/sample/createSampleLayer';
import { checkIsFilteredOutBySampleGroup } from '../../../lib/olbm/layer/sample/utils';
import { NAMESPACE } from '../../../store';
import { extentToBounds, latLngToCoordinate, toGeoJSON } from '../utils';
import { assignIdToLayer } from './LayerManager';

function createFeature(sample, projection) {
  const { latitude: lat, longitude: lng } = sample;
  const coordinates = latLngToCoordinate({ lat, lng }, projection);
  const point = new Point(coordinates);
  const feature = new Feature(point);
  feature.setId(sample.id);
  return feature;
}

// The sample layer of a sample group is created during figure loading.
// It exists until its sample group is deleted.
export default function createSampleLayer(map, sampleGroup) {
  const { interactionManager } = map;
  const storeApi = getStoreApi(map);
  const layer = olbmCreateSampleLayer(map, sampleGroup);
  assignIdToLayer(layer);

  layer.databaseLayerId = sampleGroup.id;

  layer.options = {
    type: LAYER_TYPES.SAMPLE,
  };

  layer.getSampleGroup = function () {
    return sampleGroup;
  };

  layer.checkIsSampleVisible = function (sample) {
    const viewer = map.getViewer();
    const sampleIdsBeingDeleted = NAMESPACE.getState(
      store,
      'sampleIdsBeingDeleted'
    );
    const sampleIdsBeingEdited = [];
    if (interactionManager.isEditing()) {
      const edits = interactionManager.getEdits(this);
      if (edits) {
        edits.forEach((edit) => {
          const feature = edit.getFeature();
          sampleIdsBeingEdited.push(feature.getId());
        });
      }
    }

    return (
      !sampleIdsBeingDeleted.includes(sample.id) &&
      !sampleIdsBeingEdited.includes(sample.id) &&
      !viewer.isSampleHidden(sample.id) &&
      !checkIsFilteredOutBySampleGroup(viewer.map, sample)
    );
  };

  layer.getVisibleSamples = function (extent) {
    const viewer = map.getViewer();
    return this.getSource()
      .getFeaturesInExtent(extent)
      .filter((feature) => !feature.get('isHidden'))
      .map((feature) => viewer.getSampleById(feature.getId()));
  };

  layer.hasFeature = function (feature) {
    return this.getSource().hasFeature(feature);
  };

  layer.findFeatureBySampleId = function (sampleId) {
    return this.getSource()
      .getFeatures()
      .find((feature) => feature.getId() === sampleId);
  };

  // Sample could be currentDrawingSample which could be null.
  layer.checkHasSample = function (sample) {
    return this.getSource()
      .getFeatures()
      .find((feature) => feature.getId() === sample?.id);
  };

  layer.invalidateSample = function (sample) {
    const position = this.getPosition(sample);
    const feature = this.findFeatureBySampleId(sample.id);
    feature?.getGeometry().setCoordinates(position);
    feature?.set('isHidden', !this.checkIsSampleVisible(sample));
  };

  layer.invalidate = function () {
    this.changed();
  };

  layer.addSample = function (sample) {
    if (sample.sample_group !== sampleGroup) {
      throw `The sample does not belong to this layer.`;
    }

    sample.project_figure_layer_id = sampleGroup.id;

    let feature = this.findFeatureBySampleId(sample.id);
    if (!feature) {
      feature = createFeature(sample, map.getView().getProjection());
      this.getSource().addFeature(feature);
    }
    feature.set('isHidden', !this.checkIsSampleVisible(sample));
  };

  layer.deleteFeatureBySampleId = function (sampleId) {
    const feature = this.findFeatureBySampleId(sampleId);
    if (feature) {
      this.getSource().removeFeature(feature);
    }
  };

  layer.getIconSize = function (sample) {
    return sample.icon_size;
  };

  layer.setIconSize = function (sample, value) {
    sample.icon_size = value;
  };

  layer.getIconRotation = function (sample) {
    return sample.icon_rotation;
  };

  layer.setIconRotation = function (sample, value) {
    sample.icon_rotation = value;
  };

  layer.getIconOpacity = function (sample) {
    return sample.icon_opacity;
  };

  layer.setIconOpacity = function (sample, value) {
    sample.icon_opacity = value;
  };

  layer.getIsLabelHidden = function (sample) {
    return sample.is_label_hidden;
  };

  layer.setIsLabelHidden = function (sample, value) {
    sample.is_label_hidden = value;
  };

  layer.getLabelColor = function (sample) {
    return sample.label_color;
  };

  layer.setLabelColor = function (sample, value) {
    sample.label_color = value;
  };

  layer.getLabelShadowColor = function (sample) {
    return sample.label_shadow_color;
  };

  layer.setLabelShadowColor = function (sample, value) {
    sample.label_shadow_color = value;
  };

  layer.getLabelSize = function (sample) {
    return sample.label_size;
  };

  layer.setLabelSize = function (sample, value) {
    sample.label_size = value;
  };

  layer.getIsLabelUnderlined = function (sample) {
    return sample.is_label_underlined;
  };

  layer.setIsLabelUnderlined = function (sample, value) {
    sample.is_label_underlined = value;
  };

  layer.getIsLabelAsteriskAppended = function (sample) {
    return sample.is_label_asterisk_appended;
  };

  layer.setIsLabelAsteriskAppended = function (sample, value) {
    sample.is_label_asterisk_appended = value;
  };

  layer.getStylingPriority = function (sample) {
    return sample.styling_priority;
  };

  layer.setStylingPriority = function (sample, value) {
    sample.styling_priority = value;
  };

  layer.toGeoJSON = function () {
    const features = [];

    this.getSource()
      .getFeatures()
      .forEach((feature) => {
        const sample = map.getViewer().getSampleById(feature.getId());
        const featureClone = feature.clone();
        featureClone.setProperties({
          name: sample.custom_title,
          labtitle: sample.lab_title,
          lat: sample.latitude,
          lng: sample.longitude,
          dpth1: sample.start_depth,
          dpth2: sample.end_depth,
          date: sample.sampled_date,
        });
        features.push(featureClone);
      });

    return toGeoJSON(map, features);
  };

  layer.getBounds = function (padding = 0) {
    const source = this.getSource();
    const extent = source.getFeatures().length
      ? source.getExtent()
      : map.getView().calculateExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  return layer;
}
