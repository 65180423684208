import store from '@/js/store';
import EventBus from '@component-library/EventBus';
import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import GeometryType from 'ol/geom/GeometryType';
import { fromCircle } from 'ol/geom/Polygon';
import { Draw } from 'ol/interaction';
import { Vector as VectorSource } from 'ol/source';
import Vue from 'vue';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { useShapeProperties } from '../../../composables';
import { NAMESPACE } from '../../../store';
import { callout, createDrawingLayer, createTextLayer } from '../layers';
import { createDrawingStyle } from '../styles';
import { coordinateToLatLng } from '../utils';

export function createBox(map) {
  return function (coordinates, opt_geometry, projection) {
    const topLeft = coordinates[0];
    const bottomRight = coordinates[coordinates.length - 1];
    const topLeftPixel = map.getPixelFromCoordinate(topLeft);
    const bottomRightPixel = map.getPixelFromCoordinate(bottomRight);
    const xDelta = bottomRightPixel[0] - topLeftPixel[0];
    const topRight = map.getCoordinateFromPixel([
      topLeftPixel[0] + xDelta,
      topLeftPixel[1],
    ]);
    const bottomLeft = map.getCoordinateFromPixel([
      bottomRightPixel[0] - xDelta,
      bottomRightPixel[1],
    ]);
    const boxCoordinates = [
      [bottomLeft, bottomRight, topRight, topLeft, bottomLeft],
    ];
    let geometry = opt_geometry;
    if (geometry) {
      geometry.setCoordinates(boxCoordinates);
    } else {
      geometry = new Polygon(boxCoordinates);
    }
    return geometry;
  };
}

function getRectangleOptions(map) {
  return {
    type: 'Circle',
    geometryFunction: createBox(map),
  };
}

function getPointerTipForPolygon(sketchFeatures, drawingName) {
  const lineSketchFeature = sketchFeatures.find(
    (sketchFeature) =>
      sketchFeature.getGeometry().getType() === GeometryType.LINE_STRING
  );
  if (lineSketchFeature.getGeometry().getCoordinates().length < 3) {
    // There are not enough coordinates to create a polygon drawing.
    return `Click to continue drawing ${drawingName}.`;
  } else {
    return `Click the first point or double click to close the ${drawingName}.`;
  }
}

function getPointerTipForPolyline(sketchFeatures, drawingName) {
  const lineSketchFeature = sketchFeatures.find(
    (sketchFeature) =>
      sketchFeature.getGeometry().getType() === GeometryType.LINE_STRING
  );
  if (lineSketchFeature.getGeometry().getCoordinates().length < 3) {
    // There are not enough coordinates to create a polyline drawing.
    return `Click to continue drawing ${drawingName}.`;
  } else {
    return `Click the last point or double click to finish the ${drawingName}.`;
  }
}

export default function createDraw(map, options) {
  const { getShapeProperty, setShapeProperty } = useShapeProperties();
  const { type: layerType } = options;
  let pointerTip;

  let drawOptions = {
    style: createDrawingStyle(map, {
      getPointerTip: () => pointerTip,
    }),
  };

  if (layerType === LAYER_TYPES.RECTANGLE) {
    drawOptions = {
      ...drawOptions,
      ...getRectangleOptions(map),
    };
    pointerTip = 'Click and move to draw rectangle.';
  } else if (layerType === LAYER_TYPES.CIRCLE) {
    drawOptions = {
      ...drawOptions,
      type: 'Circle',
    };
    pointerTip = 'Click and move to draw circle.';
  } else if (layerType === LAYER_TYPES.POLYGON) {
    drawOptions = {
      ...drawOptions,
      type: 'Polygon',
    };
    pointerTip = 'Click to start drawing polygon.';
  } else if (layerType === LAYER_TYPES.SITE_BOUNDARY) {
    drawOptions = {
      ...drawOptions,
      type: 'Polygon',
    };
    pointerTip = 'Click to start drawing boundary.';
  } else if (layerType === LAYER_TYPES.POLYLINE) {
    drawOptions = {
      ...drawOptions,
      type: 'LineString',
    };
    pointerTip = 'Click to start drawing line.';
  } else if (layerType === LAYER_TYPES.CHAINAGE) {
    drawOptions = {
      ...drawOptions,
      type: 'LineString',
      maxPoints: 2,
    };
    pointerTip = 'Click to start drawing chainage.';
  } else if (layerType === LAYER_TYPES.ARROW) {
    drawOptions = {
      ...drawOptions,
      type: 'LineString',
    };
    pointerTip = 'Click to start drawing arrow.';
  } else if (layerType === LAYER_TYPES.TEXT) {
    drawOptions = {
      ...drawOptions,
      type: 'Point',
    };
    pointerTip = 'Click to add text.';
  } else if (layerType === LAYER_TYPES.CALL_OUT) {
    drawOptions = {
      ...drawOptions,
      type: 'Point',
    };
    pointerTip = 'Click to add a call-out.';
  } else if (layerType === LAYER_TYPES.SAMPLE) {
    drawOptions = {
      ...drawOptions,
      type: 'Point',
    };
    pointerTip = 'Click to place marker.';
  } else if (layerType === LAYER_TYPES.HEDGE) {
    drawOptions = {
      ...drawOptions,
      ...getRectangleOptions(map),
    };
    pointerTip = 'Click and move to draw a hedge.';
  } else {
    throw new Error(`Drawing a ${layerType} is not supported.`);
  }

  const source = new VectorSource({ wrapX: false });
  source.on('addfeature', ({ feature }) => {
    const viewer = map.getViewer();
    let layer;

    if (layerType === LAYER_TYPES.SAMPLE) {
      const { currentDrawingSample: sample } = viewer;
      const coordinate = feature.getGeometry().getCoordinates();
      const { lat, lng } = coordinateToLatLng(
        coordinate,
        map.getView().getProjection()
      );
      Vue.set(sample, 'latitude', lat);
      Vue.set(sample, 'longitude', lng);
      EventBus.$emit('drawSample', sample);
      viewer.map.layerManager.drawBuiltinCallouts(sample);
      layer = viewer.map.layerManager.findSampleLayerBySampleGroup(
        sample.sample_group
      );
    } else {
      if (layerType === LAYER_TYPES.TEXT) {
        const position = feature.getGeometry().getCoordinates();
        layer = createTextLayer(map, position, false);
      } else if (layerType === LAYER_TYPES.CALL_OUT) {
        const position = feature.getGeometry().getCoordinates();
        layer = callout.createLayer(map, position);
      } else if (
        layerType === LAYER_TYPES.CIRCLE &&
        feature.getGeometry().getType() !== GeometryType.POLYGON
      ) {
        // Convert circle to a regular polygon.
        source.removeFeature(feature);
        const circle = feature.getGeometry();
        const polygon = fromCircle(circle, 128);
        feature = new Feature(polygon);
        // The addfeature event of the source will be triggered again.
        source.addFeature(feature);
        return;
      } else {
        layer = createDrawingLayer(map, source);
      }

      // The options needs to be updated because they could have been
      // changed since the draw was created.
      options = {
        ...options,
        ...viewer.getScaledLayerProperties(
          NAMESPACE.getState(store, 'shapeProperties')
        ),
      };

      layer.applyOptions(options);
      viewer.currentDrawingLayer = layer;
      viewer.map.layerManager.addLayer(viewer.currentDrawingLayer);
    }

    if (layer) {
      viewer.setCurrentLayerBackup();
      viewer.map.interactionManager.endDraw();
      EventBus.$emit('notifyFeatureCreated', layer);

      if (viewer.continueToAddFeature) {
        if (viewer.map.layerManager.checkIsTextRequired(layer)) {
          let text = getShapeProperty('text');
          if (!text) {
            text = map.layerManager.generateTextPlaceholder(layer);
            setShapeProperty('text', text);
            layer.applyOptions({
              ...layer.options,
              text,
            });
          }
        }

        EventBus.$emit('completeDrawLayer');
      } else {
        EventBus.$emit('openFeature', {
          layer,
          feature:
            layer.options.type === LAYER_TYPES.SAMPLE
              ? layer.findFeatureBySampleId(viewer.currentDrawingSample.id)
              : null,
        });
      }
    }
  });

  drawOptions = {
    ...drawOptions,
    source,
  };
  const draw = new Draw(drawOptions);

  // Used by geometry editors.
  draw.addGeometry = function (geometry) {
    if (source.getFeatures().length > 0) {
      return;
    }

    const feature = new Feature(geometry);
    source.addFeature(feature);
  };

  function changeCursorTip() {
    const sketchFeatures = draw.getOverlay().getSource().getFeatures();
    if (layerType === LAYER_TYPES.POLYGON) {
      pointerTip = getPointerTipForPolygon(sketchFeatures, 'polygon');
    } else if (layerType === LAYER_TYPES.SITE_BOUNDARY) {
      pointerTip = getPointerTipForPolygon(sketchFeatures, 'boundary');
    } else if (layerType === LAYER_TYPES.POLYLINE) {
      pointerTip = getPointerTipForPolyline(sketchFeatures, 'line');
    } else if (layerType === LAYER_TYPES.ARROW) {
      pointerTip = getPointerTipForPolyline(sketchFeatures, 'arrow');
    } else {
      pointerTip = 'Release mouse to finish drawing.';
    }
  }

  draw.on('drawstart', () => {
    map.on('click', changeCursorTip);
  });

  draw.on(['drawend', 'drawabort'], () => {
    map.un('click', changeCursorTip);
  });

  return draw;
}
