import {
  BUFFER,
  CALL_OUT,
  SAMPLE_GROUP,
  SAMPLE_GROUP_SUB_FOLDER,
  SERVICE,
} from '@component-library/business-logic/mapping/layer/types';
import helpers from '../helpers';
import { getSampleTitle } from '../lib/olbm/layer/sample/utils';
import { checkIsContours as _checkIsContours } from '../lib/olbm/layer/shape/utils';

export * as LAYER_TYPES from '@component-library/business-logic/mapping/layer/types';
export * as LAYER_USAGES from '@component-library/business-logic/mapping/layer/usages';

function searchLayerTree(layerTree, compareFn, isMultiple = false) {
  const result = [];
  for (let l of layerTree) {
    if (compareFn(l)) {
      result.push(l);
    }

    if (result.length === 1 && !isMultiple) {
      break;
    }

    if (l.children?.length) {
      result.push(...searchLayerTree(l.children, compareFn, isMultiple));
    }

    if (result.length === 1 && !isMultiple) {
      break;
    }
  }
  return result;
}

export function findLayer(layerTree, fn) {
  const [result] = searchLayerTree(layerTree, fn);
  return result;
}

export function findLayerById(layerTree, id) {
  return findLayer(layerTree, (l) => l.id === id);
}

export function flattenLayerTree(layerTree) {
  const layers = [];
  for (let i = 0; i < layerTree.length; i++) {
    const layer = layerTree[i];
    layers.push(layer);
    if (layer.children?.length) {
      layers.push(...flattenLayerTree(layer.children));
    }
  }
  return layers;
}

export function getChildCount(layerTree, id) {
  return findLayerById(layerTree, id)?.children?.length ?? 0;
}

export function getLayerTitle(layer) {
  return layer.text;
}

export function checkIsServiceLayerFolder(layer) {
  const { type, esri_type, service } = layer.data.properties;
  return type === 'folder' && (!!esri_type || !!service);
}

export function checkIsContours(layer) {
  const { type, usage } = layer.data.properties;
  return _checkIsContours(type, usage);
}

export function checkIsLayerAlwaysHidden(layer) {
  const { type, isBuiltin } = layer.data.properties;
  return type === BUFFER || (type === CALL_OUT && isBuiltin);
}

export function checkIsLayerSearchMatched(
  pattern,
  layer,
  samples,
  shouldSkipLayerText = false
) {
  const {
    text,
    data: {
      properties: { type: layerType },
    },
  } = layer;

  if (!shouldSkipLayerText && pattern.test(text)) {
    return {
      isSearchMatched: true,
      isCausedBySamples: false,
    };
  }

  if (
    [SAMPLE_GROUP, SAMPLE_GROUP_SUB_FOLDER].includes(layerType) &&
    samples.some((s) => pattern.test(getSampleTitle(s)))
  ) {
    return {
      isSearchMatched: true,
      isCausedBySamples: true,
    };
  }

  if (
    layerType === SERVICE &&
    helpers.methods
      .getSubLayers(layer)
      .some((sl) => pattern.test(sl.data.label))
  ) {
    return {
      isSearchMatched: true,
      isCausedBySamples: false,
    };
  }

  return {
    isSearchMatched: false,
    isCausedBySamples: false,
  };
}
