import { computed, ref } from 'vue';

import useLegacyStore from './useLegacyStore';
import axios from 'axios';
import { getUniqueValuesFromArray } from '../utils';

type ScenarioType = 'document' | 'landuse' | 'criteria';

export type HydrocarbonSettings = {
  soil_type?: string | null;
  depth_from?: number | null;
  depth_to?: number | null;
  pathway?: string | null;
};

export type ScenarioParameters = {
  landuse_id?: number;
  document_id?: number;
  criteria_set_id?: number;
  factor?: number;
  hydrocarbon_settings?: HydrocarbonSettings | null;
  is_aup?: boolean;
  is_eil?: boolean;
  ignore_hydrocarbon_lookup?: boolean;
};

type ScenarioStylingRequest = {};

export type ScenarioOptions = {
  exceed_above_upper_range?: boolean;
  dont_exceed_below_lower_range?: boolean;
  exceed_when_no_criteria?: boolean;
  produce_consumption_percentage?: boolean;
  hh_scenario_id?: number | null;
  groundwater_depth?: string | null;
  test_hydrocarbons?: number;
  replace_na_values?: number;
};

type ScenarioUpdateRequest = {
  scenario_id?: number;
  factor?: number;
  options?: ScenarioOptions;
  hydrocarbon_settings?: HydrocarbonSettings | null;
  eil_configuration?: any;
};

type ProjectScenario = {
  id: number;
  criteria_type: ScenarioType;
  document_id: number | null;
  scenario_id: number | null;
  criteria_set_id: number | null;
  options: ScenarioOptions;
  factor?: number;
  hydrocarbon_settings: HydrocarbonSettings | null;
};

export const checkHydrocarbonSettings = (
  scenarioParameters: ScenarioParameters,
  scenario: ProjectScenario,
  ignoreHydrocarbonCheck: boolean = false
) => {
  const hydroSettings = scenarioParameters.hydrocarbon_settings || null;
  if (
    ignoreHydrocarbonCheck ||
    (!hydroSettings && !scenario.hydrocarbon_settings)
  ) {
    return true;
  }

  if (!hydroSettings || !scenario.hydrocarbon_settings) {
    return false;
  }

  // need to check this, has to handle nullables
  return (
    scenario.hydrocarbon_settings.soil_type === hydroSettings.soil_type &&
    scenario.hydrocarbon_settings.depth_from === hydroSettings.depth_from &&
    scenario.hydrocarbon_settings.depth_to === hydroSettings.depth_to &&
    scenario.hydrocarbon_settings.pathway === hydroSettings.pathway
  );
};

const useScenarioManager = () => {
  const store = useLegacyStore();

  const isUpdating = ref(false);
  const togglingScenario = ref<ScenarioParameters | null>(null);

  const scenarios = computed(() => {
    return store.state.dashboard.scenarios;
  });

  const createScenario = async (
    scenarioParameters: ScenarioParameters,
    type: ScenarioType,
    options: ScenarioOptions | null = null,
    shouldProcess: boolean = false
  ) => {
    const { data } = await axios.post(`/scenario-management`, {
      ...scenarioParameters,
      type,
      options: options || getDefaultSettings(scenarioParameters.landuse_id),
    });

    store.dispatch('dashboard/createScenarios', [data.scenario]);

    if (shouldProcess) {
      await processScenario(scenarioParameters);
    }

    return data.scenario;
  };

  const _getScenarioTypeByParamters = (
    scenarioParameters: ScenarioParameters
  ): ScenarioType => {
    if (scenarioParameters?.document_id && scenarioParameters?.landuse_id) {
      return 'document';
    } else if (scenarioParameters?.landuse_id) {
      return 'landuse';
    }
    return 'criteria';
  };

  const toggleScenario = async (
    scenarioParameters: ScenarioParameters,
    options: ScenarioOptions | null = null,
    onlyRemoveScenarios: boolean = false,
    onlyToggleFirstScenario: boolean = false
  ) => {
    /**
     * This allow us to handle multiple scenarios, for example, when we have multiple factors, or multiple hydrocarbon settings
     */
    const filteredScenarios = scenarios.value.filter(
      getProjectScenarioFilterMethod(scenarioParameters)
    );

    togglingScenario.value = scenarioParameters;
    isUpdating.value = true;

    if (filteredScenarios.length === 0 && !onlyRemoveScenarios) {
      const newScenario = await createScenario(
        scenarioParameters,
        _getScenarioTypeByParamters(scenarioParameters),
        options
      );

      filteredScenarios.push(newScenario);
    }

    for (const scenario of filteredScenarios) {
      const shouldProcess = !isScenarioToggled(scenarioParameters);
      if (onlyRemoveScenarios && shouldProcess) {
        continue;
      }

      const scenarioGroup = store.getters['dashboard/get_scenario_group'];

      const {
        data: { items },
      } = await axios.post(
        `/dashboard/scenario-group/${scenarioGroup.id}/toggle-scenario/${scenario.id}`
      );

      store.dispatch('dashboard/updateProcessedGroupField', {
        group_id: scenarioGroup.id,
        key: 'group_items',
        value: items,
      });

      if (shouldProcess) {
        await processScenario(scenarioParameters);
      }

      if (!onlyRemoveScenarios && onlyToggleFirstScenario) {
        break;
      }
    }

    togglingScenario.value = null;
    isUpdating.value = false;
  };

  const removeScenario = async (scenarioParameters: ScenarioParameters) => {
    const scenario = getProjectScenario(scenarioParameters);
    if (!scenario || !isScenarioToggled(scenarioParameters)) {
      return;
    }

    isUpdating.value = true;

    const scenarioGroup = store.getters['dashboard/get_scenario_group'];

    const {
      data: { items },
    } = await axios.post(
      `/dashboard/scenario-group/${scenarioGroup.id}/toggle-scenario/${scenario.id}`
    );

    store.dispatch('dashboard/updateProcessedGroupField', {
      group_id: scenarioGroup.id,
      key: 'group_items',
      value: items,
    });

    isUpdating.value = false;
  };

  const getProjectScenarioFilterMethod = (
    scenarioParameters: ScenarioParameters
  ) => {
    const matchesScenario = (s: ProjectScenario) => {
      const checkFactor = () => {
        return (
          scenarioParameters.factor === undefined ||
          s.factor == scenarioParameters.factor
        );
      };

      //s: ProjectScenario from enviro TS
      switch (_getScenarioTypeByParamters(scenarioParameters)) {
        case 'document':
          return (
            s.document_id === scenarioParameters.document_id &&
            s.scenario_id === scenarioParameters.landuse_id &&
            checkFactor() &&
            checkHydrocarbonSettings(
              scenarioParameters,
              s,
              scenarioParameters.ignore_hydrocarbon_lookup
            )
          );
        case 'landuse':
          return (
            s.scenario_id === scenarioParameters.landuse_id &&
            checkFactor() &&
            (scenarioParameters.is_aup !== undefined
              ? s.options && s.options.hh_scenario_id
              : !s.options || s.options.hh_scenario_id === undefined)
          );
        default:
          return s.criteria_set_id === scenarioParameters.criteria_set_id;
      }
    };

    return matchesScenario;
  };

  const getProjectScenarioIndex = (scenarioParameters: ScenarioParameters) => {
    return scenarios.value.findIndex(
      getProjectScenarioFilterMethod(scenarioParameters)
    );
  };

  const getProjectScenario = (scenarioParameters: ScenarioParameters) => {
    return scenarios.value[getProjectScenarioIndex(scenarioParameters)] || null;
  };

  const getProjectScenarioFactors = (
    scenarioParameters: ScenarioParameters
  ) => {
    const paramsWithoutFactor = { ...scenarioParameters };
    delete paramsWithoutFactor.factor;

    return getUniqueValuesFromArray(
      scenarios.value
        .filter(getProjectScenarioFilterMethod(paramsWithoutFactor))
        .map((s) => s.factor.toString())
    );
  };

  const getProjectScenarioHydrocarbonSettings = (
    scenarioParameters: ScenarioParameters
  ) => {
    return scenarios.value
      .filter(getProjectScenarioFilterMethod(scenarioParameters))
      .filter((s) => s.hydrocarbon_settings)
      .map((s) => s.hydrocarbon_settings);
  };

  const isScenarioToggled = (scenarioParameters: ScenarioParameters) => {
    let isToggled = false;

    // If the ignore hydrocarbon param is toggled or factors, there can be multiple.
    const toggledScenarios = scenarios.value.filter(
      getProjectScenarioFilterMethod(scenarioParameters)
    );

    for (const scenario of toggledScenarios) {
      if (store.getters['dashboard/is_scenario_toggled'](scenario.id)) {
        isToggled = true;
        break;
      }
    }

    return isToggled;
  };

  const getDefaultSettings = (landuse_id: number | undefined) => {
    let defaultSettings: ScenarioOptions = {
      exceed_above_upper_range: false,
      dont_exceed_below_lower_range: false,
      exceed_when_no_criteria: false,
    };

    // default groundwater settings
    if (landuse_id === 4) {
      defaultSettings = {
        ...defaultSettings,
        test_hydrocarbons: 2,
        replace_na_values: 0,
      };
    }

    return defaultSettings;
  };

  const getScenarioSettings = (scenarioParameters: ScenarioParameters) => {
    const scenario = getProjectScenario(scenarioParameters);
    return scenario?.options || getDefaultSettings(scenario?.landuse_id);
  };

  const updateScenarioSettings = async (
    scenarioParameters: ScenarioParameters,
    options: ScenarioOptions
  ) => {
    let scenario = getProjectScenario(scenarioParameters);

    isUpdating.value = true;
    if (scenario === null) {
      scenario = await createScenario(
        scenarioParameters,
        _getScenarioTypeByParamters(scenarioParameters),
        options
      );
    } else {
      await axios.put(`/scenario-management/${scenario.id}`, {
        options,
      });
    }

    await processScenario(scenarioParameters);

    isUpdating.value = false;
  };

  const getScenarioSettingExcludeOptions = (
    scenarioParameters: ScenarioParameters
  ) => {
    const type = _getScenarioTypeByParamters(scenarioParameters);

    if (type === 'document') {
      return [
        'exceed_when_no_criteria',
        'dont_exceed_below_lower_range',
        'produce_consumption_percentage',
      ];
    } else if (type === 'landuse') {
      return ['exceed_when_no_criteria', 'dont_exceed_below_lower_range'];
    }

    return ['produce_consumption_percentage'];
  };

  const getScenarioStyling = (scenarioParameters: ScenarioParameters) => {
    const scenario = getProjectScenario(scenarioParameters);

    // doesn't handle background
    const defaultStyling = { background_color: '#f8d7da' };

    if (scenario) {
      const styling =
        store.getters['data-table/get_style_by_scenario'](scenario);

      if (styling && !styling.exceedance_cell_styling) {
        styling.exceedance_cell_styling = defaultStyling;
      }

      if (styling) {
        return styling;
      }
    }

    return {
      title: null,
      exceedance_cell_styling: defaultStyling,
    };
  };

  const updateScenarioStyling = async (
    scenarioParameters: ScenarioParameters,
    styling: ScenarioStylingRequest
  ) => {
    let scenario = getProjectScenario(scenarioParameters);

    isUpdating.value = true;

    if (scenario === null) {
      scenario = await createScenario(
        scenarioParameters,
        _getScenarioTypeByParamters(scenarioParameters)
      );
    }

    let setId = store.state.project.scenario_set_id;
    if (!setId) {
      const { data } = await axios.post('/dashboard/scenario', {
        set: {
          title: 'Untitled Set',
        },
      });
      setId = data.set_id;
    }

    const { data } = await axios.put(
      `/dashboard/scenario/${setId}/scenario-styling`,
      {
        scenario: {
          ...styling,
          ...scenario,
        },
      }
    );

    store.dispatch('data-table/modifyScenarioSet', data.set);
    store.dispatch('updateProject', {
      scenario_set_id: setId,
    });

    isUpdating.value = false;
  };

  const updateScenario = async (
    scenarioParameters: ScenarioParameters,
    data: ScenarioUpdateRequest
  ) => {
    let scenario = getProjectScenario(scenarioParameters);

    if (!scenario) {
      scenario = await createScenario(
        {
          ...scenarioParameters,
          ...data,
        },
        _getScenarioTypeByParamters(scenarioParameters),
        null,
        true
      );
    }

    isUpdating.value = true;
    await axios.put(`/scenario-management/${scenario.id}`, data);
    isUpdating.value = false;

    store.dispatch('dashboard/updateScenario', {
      id: scenario.id,
      ...data,
    });
  };

  const processScenario = async (scenarioParameters: ScenarioParameters) => {
    const scenario = getProjectScenario(scenarioParameters);
    if (!scenario) {
      return;
    }

    await axios.post(`/scenario-management/${scenario.id}/process`);
  };

  const updateFactor = async (
    scenarioParameters: ScenarioParameters,
    newFactor: number
  ) => {
    await updateScenario(scenarioParameters, {
      factor: newFactor,
    });
  };

  const addScenarioByFactor = async (
    scenarioParameters: ScenarioParameters
  ) => {
    if (getProjectScenarioIndex(scenarioParameters) !== -1) {
      return;
    }
    await toggleScenario(scenarioParameters);
  };

  const updateScenarioOrdering = async (scenarios: ProjectScenario[]) => {
    const parsedScenarios = scenarios.map((s, sIndex) => {
      return {
        id: s.id,
        order: sIndex,
      };
    });

    store.dispatch('dashboard/updateScenarioOrdering', parsedScenarios);

    await axios.put(`/scenario-management/update-ordering`, {
      scenarios: parsedScenarios,
    });
  };

  return {
    isUpdating,
    togglingScenario,
    toggleScenario,
    removeScenario,
    isScenarioToggled,
    getProjectScenario,
    getProjectScenarioFactors,
    getProjectScenarioHydrocarbonSettings,
    getScenarioSettings,
    updateScenarioSettings,
    updateScenario,
    getScenarioSettingExcludeOptions,
    getScenarioStyling,
    updateScenarioStyling,
    processScenario,
    updateFactor,
    addScenarioByFactor,
    updateScenarioOrdering,
  };
};

export default useScenarioManager;
