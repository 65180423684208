import { ResultType } from '@component-library/business-model/expression';
import { FieldTypeIds } from '@component-library/fields';
import tinygradient from 'tinygradient';
import { GREEN, RED } from '../color';
import { flattenLayerTree, LAYER_TYPES } from '../layer';

export const INPUT_TYPES = {
  ENVIRO: 1,
  GATHER: 2,
};

export const ACTION_TYPES = {
  GENERIC: 1,
  ANALYZE_SAMPLES: 2,
  CREATE: 3,
};

export const DEFAULT_RANGE = {
  min: 0,
  max: 0,
  count: 0,
};

export function getInputType(datasource) {
  if ('chemicals' in datasource) {
    return INPUT_TYPES.ENVIRO;
  } else if ('templateTab' in datasource) {
    return INPUT_TYPES.GATHER;
  } else {
    throw 'Unknown datasource';
  }
}

export function getColors(range) {
  const { min, max } = range;
  return [
    {
      label: String(min),
      value: GREEN,
    },
    {
      label: String(max),
      value: RED,
    },
  ];
}

export function generateRange(colors) {
  return {
    min: parseFloat(colors[0].label),
    max: parseFloat(colors[colors.length - 1].label),
  };
}

export function turnColorsToGradient(colors) {
  const { label: startLabel, value: startColor } = colors[0];
  const { label: endLabel, value: endColor } = colors[colors.length - 1];

  const tg = tinygradient([
    { color: startColor, pos: 0 },
    { color: endColor, pos: 1 },
  ]);

  const startValue = parseFloat(startLabel);
  const endValue = parseFloat(endLabel);
  const difference = endValue - startValue;

  return colors.map((c, cIndex) => {
    if ([0, colors.length - 1].includes(cIndex)) {
      return c;
    }

    const { label } = c;
    const value = parseFloat(label);
    const pos = (value - startValue) / difference;
    return {
      ...c,
      value: tg.rgbAt(pos).toHexString(),
    };
  });
}

export function getElevationInterval(range) {
  const { min, max } = range;
  return Number(((max - min) / 10).toPrecision(3));
}

export function getEligibleTemplateSections(templateTab) {
  return templateTab.sections.filter(
    (s) => !s.is_repeatable && !!getEligibleTemplateFields(s).length
  );
}

export function getEligibleTemplateFields(templateSection) {
  return templateSection.template_fields.filter(
    (f) =>
      f.field_type_id === FieldTypeIds.NUMBER ||
      (f.field_type_id === FieldTypeIds.EXPRESSION &&
        f.options.expression.resultType === ResultType.NUMBER)
  );
}

export function getClipBoundaries(layers) {
  return flattenLayerTree(layers).filter((l) =>
    [
      LAYER_TYPES.RECTANGLE,
      LAYER_TYPES.CIRCLE,
      LAYER_TYPES.POLYGON,
      LAYER_TYPES.SITE_BOUNDARY,
    ].includes(l.data.properties.type)
  );
}
