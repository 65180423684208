import _uniq from 'lodash/uniq';
import _upperFirst from 'lodash/upperFirst';
import Api from '../api';
import { BLACK } from '../business-logic/color';
import { LAYER_TYPES, checkIsLayerAlwaysHidden } from '../business-logic/layer';
import { getSampleTitle } from '../lib/olbm/layer/sample/utils';
import { getGetter } from '../view-utils';

class TreeView {
  static subFolderIdCounter = 0;

  static _createSubFolderId() {
    return `sub_folder_${TreeView.subFolderIdCounter++}`;
  }

  static prepareDBLayersForTree(layers) {
    return layers.map((pLayer) => {
      pLayer.data = this.convertLayerData(pLayer);
      // Children could be undefined when restoring a deleted layer.
      pLayer.children = pLayer.children ?? [];

      if (checkIsLayerAlwaysHidden(pLayer)) {
        pLayer.state = {
          ...(pLayer.state ?? {}),
          visible: false,
        };
      }

      pLayer.children.map((cLayer) => {
        cLayer.data = this.convertLayerData(cLayer);
        cLayer.state = { dropable: false };
      });

      return pLayer;
    });
  }

  static convertLayerData(layer, layerUid = null) {
    if (layer.data) {
      return layer.data;
    }

    let layerJSON = JSON.parse(layer.geojson);
    let properties = layerJSON.properties || {};

    let { title } = properties;
    title = title?.trim() ?? '';
    const type = properties.type || '';
    layer.text = title || _upperFirst(type.split(/[-_]/).join(' '));

    if (properties.type === LAYER_TYPES.TEXT && !properties.color) {
      properties.color = BLACK;
      layer.geojson = JSON.stringify(layerJSON);
    }

    // The isScc is renamed to isBuiltin.
    if (properties.type === LAYER_TYPES.CALL_OUT && properties.isScc) {
      delete properties.isScc;
      properties.isBuiltin = true;
    }

    return {
      id: layer.id,
      properties,
      layerUid,
      marker_identifier: layer.marker_identifier,
      created_by_id: layer.created_by_id,
      // The poly gather sample only exists if the layer is a polyline, polygon, circle, rectangle or siteboundary
      // with data collected from Gather.
      polyGatherSample: getGetter('getPolyGatherSample')(layer.id) ?? null,
      // Required for using the same way to get icon for ESRI service layers and WFS service layer
      renderer: properties.renderer,
      isVisibleInBasemapFigure: layer.is_visible_in_basemap_figure,
      deletedAt: layer.deleted_at,
    };
  }

  static getFolderObject(figureId) {
    let properties = {
      title: 'Untitled Folder',
      type: 'folder',
    };

    return Api.modifyLayer({
      figure_id: figureId,
      layer: {
        properties: properties,
        geojson: JSON.stringify({
          //this is used for storing in DB as it's a new folder
          properties: properties,
        }),
      },
    });
  }

  static getLayersForOrdering(layerTree) {
    return layerTree.map((l) => {
      const isFolder = l.data.properties.type == LAYER_TYPES.FOLDER;
      const layerObject = {};

      if (!l.data.marker_identifier) {
        layerObject.children = l.children
          .filter((l) => l.data.properties)
          .map((c) => {
            return {
              id: c.data.id,
            };
          });
      }

      if (l.data.project_id) {
        layerObject.project_id = l.data.project_id;
      }

      if (l.data.id) {
        layerObject.id = l.data.id;
      }

      if (isFolder) {
        layerObject.geojson = JSON.stringify({
          properties: l.data.properties,
        });
      }

      layerObject.isFolder = isFolder;

      return layerObject;
    });
  }

  static searchTree(treeData, comparisonMethod, returnAll = false) {
    let childNodes = [];
    let currentNode;
    let allFoundNodes = [];

    for (let keysTree in treeData) {
      childNodes.push(treeData[keysTree]);
    }

    while (childNodes.length > 0) {
      currentNode = childNodes.pop();

      if (comparisonMethod(currentNode)) {
        allFoundNodes.push(currentNode);

        if (!returnAll) {
          return currentNode;
        }
      } else {
        if (currentNode.children && currentNode.children.length) {
          for (let i = 0; i < currentNode['children'].length; i++) {
            childNodes.push(currentNode['children'][i]);
          }
        }
      }
    }

    if (!returnAll) {
      return null;
    }

    return allFoundNodes;
  }

  static getSampleGroupById(treeData, id, isDefaultFallback = false) {
    const dsg = TreeView.searchTree(treeData, (node) => {
      return (
        node.data.properties.type === LAYER_TYPES.SAMPLE_GROUP &&
        node.data.properties.default
      );
    });

    let result = id
      ? TreeView.searchTree(treeData, (node) => {
          return (
            node.data.properties.type === LAYER_TYPES.SAMPLE_GROUP &&
            node.data.id === id
          );
        })
      : dsg;
    if (isDefaultFallback && !result) {
      result = dsg;
    }
    return result;
  }

  static _createSampleNode(sample) {
    const { id, sample_group, latitude, longitude, composite_of, deleted_at } =
      sample;
    const title = getSampleTitle(sample);
    return {
      id,
      text: title,
      state: { draggable: false, dropable: false },
      data: {
        id,
        text: title,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        composite_of,
        deletedAt: deleted_at,
        properties: {
          type: 'sample',
          sampleGroupId: sample_group.id,
        },
      },
    };
  }

  static buildSampleGroupTree(treeData, samples, shouldSkipSampleNodes = true) {
    TreeView.searchTree(
      treeData,
      (node) => {
        return node.marker_identifier;
      },
      true
    ).forEach((sampleGroup) => {
      // For a sample group, its child is either a sub folder or a sample.
      const groupedSamples = samples.filter(
        (sample) => sample.sample_group.id === sampleGroup.id
      );
      const existingSubFolders = TreeView.getSubFolders(sampleGroup);
      const subFolders = _uniq([
        ...existingSubFolders,
        ...groupedSamples.map((gs) => gs.sub_folder).filter((sf) => !!sf),
      ]).sort((sf1, sf2) => sf1.localeCompare(sf2));

      const subFolderLayers = subFolders.map((sf) => {
        const newSampleNodes = !shouldSkipSampleNodes
          ? groupedSamples
              .filter((gs) => gs.sub_folder === sf)
              .map(TreeView._createSampleNode)
          : [];

        let sfLayer = TreeView.findSubFolderLayer(sampleGroup, sf);
        if (sfLayer) {
          sfLayer.children = [...sfLayer.children, ...newSampleNodes];
        } else {
          const id = TreeView._createSubFolderId();
          const { marker_identifier } = sampleGroup;
          const properties = {
            type: LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER,
            title: sf,
            sampleGroupId: sampleGroup.id,
          };
          sfLayer = {
            id,
            geojson: { properties },
            parent_id: sampleGroup.id,
            text: sf,
            marker_identifier,
            data: {
              id,
              text: sf,
              marker_identifier,
              properties,
            },
            children: newSampleNodes,
            state: { draggable: false, dropable: false },
          };
        }
        return sfLayer;
      });

      const rootSampleNodes = !shouldSkipSampleNodes
        ? groupedSamples
            .filter((gs) => !gs.sub_folder)
            .map(TreeView._createSampleNode)
        : [];

      sampleGroup.children = [...subFolderLayers, ...rootSampleNodes];
    });
  }

  static getSubFolders(sampleGroup) {
    return sampleGroup.children
      .filter(
        (c) => c.data?.properties.type === LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER
      )
      .map((sfLayer) => sfLayer.data.text);
  }

  static findSubFolderLayer(sampleGroup, subFolder) {
    return sampleGroup.children.find(
      (c) =>
        c.data?.properties.type === LAYER_TYPES.SAMPLE_GROUP_SUB_FOLDER &&
        c.data?.text === subFolder
    );
  }
}

export default TreeView;
