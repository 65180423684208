import alpha from 'color-alpha';
import Chart from '../../../../../components/Chart.vue';
import PercentageChart from '../charts/PercentageChart.vue';
import WordCloudChart from '../charts/WordCloudChart.vue';
import NumbersChart from '../charts/NumbersChart.vue';
import SamplesChart from '../charts/SamplesChart.vue';
import * as TOOL_TYPES from '../../constants/toolTypes';
import * as SWATCHES from '../../constants/swatches';
import * as STATISTIC_TYPES from '../../constants/statisticTypes';
import * as VIEW_MODES from '../../constants/viewModes';
import { findAggregateFunctionById } from '../../constants/aggregateFunctions';
import {
  nextGraphColor,
  resetGraphColors,
} from '@component-library/business-logic/mapping/color';
import DisplacementGraph from '../charts/DisplacementGraph.vue';
import { plugins } from 'chart.js';

function createBarChartConfig(data, options, globalOptions) {
  const {
    labels,
    datasets,
    section_rows: sectionRows,
    section_fields: sectionFields,
  } = data;
  const {
    info: {
      title,
      statisticTypeId,
      dimensionFieldLabel,
      measureFieldId,
      measureFieldLabel,
      aggregateFunctionId,
      selection: [selectedBarElementIndices],
      viewModeId,
      eventSourceEnabled,
    },
    style: {
      canvas: {
        titleColor,
        titleSize,
        xAxisTickColor,
        xAxisLabelColor,
        yAxisTickColor,
        yAxisLabelColor,
      },
      graph: [{ backgroundColor }],
      axes: {
        yAxis: { beginAtZero, precision, max, min, stepSize },
      },
    },
  } = options;
  const { xAxisLabel } = options.style.axes.xAxis || {};

  let yAxisTicks = {
    color: yAxisTickColor,
    beginAtZero,
    precision,
  };

  const { highlightColor } = globalOptions;
  const borderColor = labels.map((label, index) => {
    return selectedBarElementIndices.indexOf(index) !== -1
      ? alpha(highlightColor, 1)
      : alpha(SWATCHES.COLOR_78, 0.1);
  });
  const borderWidth = labels.map((label, index) => {
    return selectedBarElementIndices.indexOf(index) !== -1 ? 4 : 0;
  });

  let yAxisLabel = '';
  if (statisticTypeId === STATISTIC_TYPES.NUMBER_OF_SAMPLES.id) {
    yAxisLabel = STATISTIC_TYPES.NUMBER_OF_SAMPLES.label;
  } else if (statisticTypeId === STATISTIC_TYPES.CUSTOM_MADE_STATISTIC.id) {
    if (!!measureFieldId && !!aggregateFunctionId) {
      const aggregateFunction = findAggregateFunctionById(aggregateFunctionId);
      yAxisLabel = `${measureFieldLabel}(${aggregateFunction.label})`;
    }
  }
  if (options.style.axes.yAxis?.yAxisLabel) {
    yAxisLabel = options.style.axes.yAxis.yAxisLabel;
  }

  const y = {
    title: {
      display: true,
      text: yAxisLabel,
      color: yAxisLabelColor,
    },
  };
  if (!!max && !isNaN(max)) {
    y['max'] = Number(max);
  }
  if (!!min && !isNaN(min)) {
    y['min'] = Number(min);
  }
  if (!!stepSize && !isNaN(stepSize)) {
    yAxisTicks = {
      ...yAxisTicks,
      stepSize,
    };
  }
  y['ticks'] = yAxisTicks;
  switch (viewModeId) {
    case VIEW_MODES.CHART.id:
      return {
        component: Chart,
        props: {
          class: 'position-relative w-100 h-100',
          type: 'bar',
          data: {
            labels,
            datasets: datasets.map((dataset, i) => ({
              label: labels[i],
              data: dataset.data,
              backgroundColor: labels.map((item) => backgroundColor[item]),
              borderColor,
              borderWidth,
            })),
          },
          options: {
            plugins: {
              title: {
                display: title.trim() !== '',
                text: title,
                color: titleColor,
                fontSize: titleSize,
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                title: {
                  display: true,
                  text:
                    xAxisLabel && xAxisLabel.length > 0
                      ? xAxisLabel
                      : dimensionFieldLabel,
                  color: xAxisLabelColor,
                },
                ticks: {
                  color: xAxisTickColor,
                },
              },
              y: y,
            },
            legend: {
              display: false,
            },
          },
        },
      };
    case VIEW_MODES.NUMBERS.id:
      return {
        component: NumbersChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          headers: [dimensionFieldLabel, yAxisLabel],
          numbers: (() => {
            const [dataset] = datasets;
            return labels.map((item, index) => {
              return {
                label: item,
                value: dataset.data[index],
                color: backgroundColor[item],
              };
            });
          })(),
          eventSourceEnabled,
          selection: selectedBarElementIndices,
        },
      };
    case VIEW_MODES.SAMPLES.id:
      return {
        component: SamplesChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          sectionRows,
          sectionFields,
        },
      };
  }
}

function createLineChartConfig(data, options, globalOptions, dashboardItemId) {
  if (Array.isArray(data)) {
    data = {
      ...data[globalOptions?.groupIndex || 0].pages[
        globalOptions?.pageIndex || 0
      ],
    };
  }
  const {
    labels,
    datasets,
    section_rows: sectionRows,
    section_fields: sectionFields,
  } = data;
  const {
    info: { title, dimensionFieldLabel, measureFieldLabel, viewModeId },
    style: {
      canvas: {
        titleColor,
        titleSize,
        legendLabelColor,
        xAxisTickColor,
        xAxisLabelColor,
        yAxisTickColor,
        yAxisLabelColor,
      },
      graph,
      axes: {
        yAxis: { beginAtZero, precision, max, min, stepSize, yAxisLabel },
      },
    },
  } = options;

  const { xAxisLabel } = options.style.axes.xAxis || {};

  let yAxisTicks = {
    color: yAxisTickColor,
    beginAtZero,
    precision,
  };
  const y = {
    title: {
      display: true,
      text:
        yAxisLabel && yAxisLabel.length > 0 ? yAxisLabel : measureFieldLabel,
      color: yAxisLabelColor,
    },
  };
  if (!!max && !isNaN(max)) {
    y['max'] = Number(max);
  }
  if (!!min && !isNaN(min)) {
    y['min'] = Number(min);
  }
  if (!!stepSize && !isNaN(stepSize)) {
    yAxisTicks = {
      ...yAxisTicks,
      stepSize,
    };
  }
  y['ticks'] = yAxisTicks;
  const graphKey =
    'dashboard-' +
    dashboardItemId +
    ':' +
    (globalOptions?.groupIndex || 0) +
    ':' +
    (globalOptions?.pageIndex || 0);

  resetGraphColors(
    graphKey,
    graph.defaultBackgroundColors ? [...graph.defaultBackgroundColors] : null
  );

  switch (viewModeId) {
    case VIEW_MODES.CHART.id:
      return {
        component: Chart,
        props: {
          class: 'w-100 h-100',
          type: 'line',
          data: {
            labels,
            datasets: datasets.map((dataset, index) => {
              const backgroundColor = nextGraphColor(graphKey);
              let i = 0;
              return {
                label: dataset.label.sample_title,
                data: dataset.data
                  .map((item) => {
                    i = (i % labels.length) + 1;
                    return { x: labels[i - 1], y: item };
                  })
                  .filter((item) => item.y !== null),
                backgroundColor,
                borderColor: backgroundColor,
                lineTension: graph.useCurvedLines ? 0.3 : 0,
                fill: false,
              };
            }),
          },
          options: {
            plugins: {
              title: {
                display: title.trim() !== '',
                text: title,
                color: titleColor,
                fontSize: titleSize,
              },
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                title: {
                  display: true,
                  text:
                    xAxisLabel && xAxisLabel.length > 0
                      ? xAxisLabel
                      : dimensionFieldLabel,
                  color: xAxisLabelColor,
                },
                ticks: {
                  color: xAxisTickColor,
                },
              },
              y: y,
            },
            legend: {
              labels: {
                color: legendLabelColor,
              },
            },
          },
        },
      };
    case VIEW_MODES.SAMPLES.id:
      return {
        component: SamplesChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          sectionRows,
          sectionFields,
        },
      };
  }
}

function createPieChartConfig(data, options, globalOptions) {
  const {
    labels,
    datasets,
    section_rows: sectionRows,
    section_fields: sectionFields,
  } = data;
  const {
    info: {
      dimensionFieldLabel,
      title,
      selection,
      viewModeId,
      eventSourceEnabled,
    },
    style: {
      canvas: { titleColor, titleSize, legendLabelColor },
      graph: [{ backgroundColor }],
    },
  } = options;
  const { highlightColor } = globalOptions;
  const borderColor = labels.map((label, index) => {
    return selection.indexOf(index) !== -1
      ? alpha(highlightColor, 1)
      : alpha(SWATCHES.COLOR_78, 0.1);
  });
  const borderWidth = labels.map((label, index) => {
    return selection.indexOf(index) !== -1 ? 4 : 0;
  });

  switch (viewModeId) {
    case VIEW_MODES.CHART.id:
      return {
        component: Chart,
        props: {
          class: 'position-relative w-100 h-100',
          type: 'pie',
          data: {
            labels,
            datasets: datasets.map((dataset) => ({
              data: dataset.data,
              backgroundColor: labels.map((item) => backgroundColor[item]),
              borderColor,
              borderWidth,
            })),
          },
          options: {
            plugins: {
              title: {
                display: title.trim() !== '',
                text: title,
                color: titleColor,
                fontSize: titleSize,
              },
            },
            maintainAspectRatio: false,
            legend: {
              labels: {
                color: legendLabelColor,
              },
            },
          },
        },
      };
    case VIEW_MODES.NUMBERS.id:
      return {
        component: NumbersChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          headers: [
            dimensionFieldLabel,
            STATISTIC_TYPES.NUMBER_OF_SAMPLES.label,
          ],
          numbers: (() => {
            const [dataset] = datasets;
            return labels.map((item, index) => {
              return {
                label: item,
                value: dataset.data[index],
                color: backgroundColor[item],
              };
            });
          })(),
          eventSourceEnabled,
          selection,
        },
      };
    case VIEW_MODES.SAMPLES.id:
      return {
        component: SamplesChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          sectionRows,
          sectionFields,
        },
      };
  }
}

function createScatterChartConfig(data, options, globalOptions) {
  const {
    datasets,
    section_rows: sectionRows,
    section_fields: sectionFields,
  } = data;
  const {
    info: { title, xAxisMeasureFieldLabel, yAxisMeasureFieldLabel, viewModeId },
    style: {
      canvas: {
        titleColor,
        titleSize,
        xAxisTickColor,
        xAxisLabelColor,
        yAxisTickColor,
        yAxisLabelColor,
      },
      graph: [{ backgroundColor }],
    },
  } = options;

  switch (viewModeId) {
    case VIEW_MODES.CHART.id:
      return {
        component: Chart,
        props: {
          class: 'position-relative w-100 h-100',
          type: 'scatter',
          data: {
            datasets: datasets.map((dataset) => ({
              data: dataset.data,
              backgroundColor,
            })),
          },
          options: {
            plugins: {
              title: {
                display: title.trim() !== '',
                text: title,
                color: titleColor,
                fontSize: titleSize,
              },
              legend: {
                display: false,
              }
            },
            maintainAspectRatio: false,
            scales: {
              x: {
                title: {
                  display: true,
                  text: xAxisMeasureFieldLabel,
                  color: xAxisLabelColor,
                },
                ticks: {
                  color: xAxisTickColor,
                },
              },
              y: {
                title: {
                  display: true,
                  text: yAxisMeasureFieldLabel,
                  color: yAxisLabelColor,
                },
                ticks: {
                  color: yAxisTickColor,
                },
              },
            },
          },
        },
      };
    case VIEW_MODES.SAMPLES.id:
      return {
        component: SamplesChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          sectionRows,
          sectionFields,
        },
      };
  }
}

function createPercentageChartConfig(data, options, globalOptions) {
  const { numberOfCreatedSamples } = data;
  const {
    info: { title, numberOfPlannedSamples },
    style: {
      canvas: { titleColor, titleSize, percentageColor, percentageSize },
    },
  } = options;

  return {
    component: PercentageChart,
    props: {
      class: 'position-relative w-100 h-100',
      data: {
        title,
        numberOfCreatedSamples,
        numberOfPlannedSamples,
      },
      options: {
        titleColor,
        titleSize,
        percentageColor,
        percentageSize,
      },
    },
  };
}

function createWordCloudChartConfig(data, options, globalOptions) {
  const {
    words,
    section_rows: sectionRows,
    section_fields: sectionFields,
  } = data;
  const {
    info: { title, viewModeId },
    style: {
      canvas: { titleColor, titleSize },
      graph: { heavyWeightColor, mediumWeightColor, lightWeightColor },
    },
  } = options;

  switch (viewModeId) {
    case VIEW_MODES.CHART.id:
      return {
        component: WordCloudChart,
        props: {
          data: {
            title,
            words,
          },
          options: {
            titleColor,
            titleSize,
            heavyWeightColor,
            mediumWeightColor,
            lightWeightColor,
          },
        },
      };
    case VIEW_MODES.SAMPLES.id:
      return {
        component: SamplesChart,
        props: {
          class: 'w-100 h-100',
          title,
          titleColor,
          titleSize,
          sectionRows,
          sectionFields,
        },
      };
  }
}

function createDisplacementGraphConfig(data, options, globalOptions) {
  if (Array.isArray(data)) {
    data = {
      ...data[globalOptions?.groupIndex || 0].pages[
        globalOptions?.pageIndex || 0
      ],
    };
  }
  return {
    component: DisplacementGraph,
    props: { data, options },
  };
}

export default (toolType, data, options, globalOptions, dashboardItemId) => {
  switch (toolType) {
    case TOOL_TYPES.BAR_CHART:
      return createBarChartConfig(data, options, globalOptions);
    case TOOL_TYPES.LINE_CHART:
      return createLineChartConfig(
        data,
        options,
        globalOptions,
        dashboardItemId
      );
    case TOOL_TYPES.PIE_CHART:
      return createPieChartConfig(data, options, globalOptions);
    case TOOL_TYPES.SCATTER_CHART:
      return createScatterChartConfig(data, options, globalOptions);
    case TOOL_TYPES.PERCENTAGE_CHART:
      return createPercentageChartConfig(data, options, globalOptions);
    case TOOL_TYPES.WORD_CLOUD_CHART:
      return createWordCloudChartConfig(data, options, globalOptions);
    case TOOL_TYPES.DISPLACEMENT_GRAPH:
      return createDisplacementGraphConfig(data, options, globalOptions);
    default:
      throw new Error(`createConfig: Unknown tool type: ${toolType}`);
  }

  return null;
};
