import Transform from 'ol-ext/interaction/Transform';
import { always, never, primaryAction } from 'ol/events/condition';
import { Modify, Pointer } from 'ol/interaction';
import { changeTransformStyle, createDrawingStyle } from '../styles';
import { checkIsVertexCoordinate, getLineFormula } from '../utils';

const SIDE_COORD_MAPPING = {
  0: [
    (coords, left, right) => {
      coords[0] = left[0];
    },
    (coords, left, right) => {
      coords[1] = left[1];
    },
    (coords, left, right) => {
      coords[2] = right[0];
    },
    (coords, left, right) => {
      coords[3] = right[1];
    },
    (coords, left, right) => {
      coords[8] = left[0];
    },
    (coords, left, right) => {
      coords[9] = left[1];
    },
  ],
  1: [
    (coords, left, right) => {
      coords[2] = left[0];
    },
    (coords, left, right) => {
      coords[3] = left[1];
    },
    (coords, left, right) => {
      coords[4] = right[0];
    },
    (coords, left, right) => {
      coords[5] = right[1];
    },
  ],
  2: [
    (coords, left, right) => {
      coords[4] = left[0];
    },
    (coords, left, right) => {
      coords[5] = left[1];
    },
    (coords, left, right) => {
      coords[6] = right[0];
    },
    (coords, left, right) => {
      coords[7] = right[1];
    },
  ],
  3: [
    (coords, left, right) => {
      coords[6] = left[0];
    },
    (coords, left, right) => {
      coords[7] = left[1];
    },
    (coords, left, right) => {
      coords[8] = right[0];
    },
    (coords, left, right) => {
      coords[9] = right[1];
    },
    (coords, left, right) => {
      coords[0] = right[0];
    },
    (coords, left, right) => {
      coords[1] = right[1];
    },
  ],
};

const CHECK_POLYGON_VERTEX_COORD_TOLERANCE = 10;

/**
 * The sides of a rectangle are numbered as the following illustration:
 *
 * --------2--------
 * |               |
 * 3               1
 * |               |
 * --------0--------
 *
 * Each side is represented as a formula in the screen coordinate system.
 * @param {ol/Map} map
 * @param {ol/Feature} feature
 * @returns
 */
function getSides(map, feature) {
  const geom = feature.getGeometry();
  const coords = geom.getLinearRing(0).getCoordinates();
  const sides = {};
  let sideId = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    const coordA = coords[i];
    const coordB = coords[i + 1];
    sides[sideId++] = getLineFormula(map, coordA, coordB);
  }
  return sides;
}

function getCurrentSideId(sides, pixel) {
  let result = null;
  const tolerance = 5;
  Object.keys(sides).forEach((sideId) => {
    const side = sides[sideId];
    if (side.hasOwnProperty('k')) {
      const difference = pixel[1] - (side['k'] * pixel[0] + side['b']);
      if (Math.abs(difference) <= tolerance) {
        result = parseInt(sideId, 10);
      }
    } else {
      const difference = pixel[0] - side['b'];
      if (Math.abs(difference) <= tolerance) {
        result = parseInt(sideId, 10);
      }
    }
  });
  return result;
}

/**
 *
 * @param {ol/Map} map
 * @param {Object} options
 * @returns
 */
export default function createSquareEdit(
  map,
  options = {
    translate: false,
    translateFeature: true,
    draggingOnSidesEnabled: true,
    rotate: true,
  }
) {
  const interactions = [];

  // The transform interaction is from ol-ext. It is used to do square resizing, rotation and moving.
  // For usage of transform, see https://viglino.github.io/ol-ext/examples/interaction/map.interaction.transform.html
  const { draggingOnSidesEnabled, ...transformOptions } = options;
  const transform = new Transform({
    hitTolerance: 2,
    scale: true,
    keepAspectRatio: always,
    stretch: false,
    translateBBox: false,
    // Don't use the Select interaction so that the shape can keep being selected
    selection: false,
    enableRotatedTransform: true,
    ...transformOptions,
  });
  interactions.push(transform);

  // Features from transform are managed by other interactions.
  const features = transform.getFeatures();
  const getFeatureBeingModified = () => features.item(0);

  // The modify interaction and the pointer interaction work together
  // to resize the square side by side.
  // The modify condition limits that vertexes of the square can't be
  // used to do the modification.
  function modifyCondition(mapBrowerEvent) {
    const isPrimaryAction = primaryAction(mapBrowerEvent);
    const feature = getFeatureBeingModified();
    if (!feature) {
      return false;
    }
    const geom = feature.getGeometry();
    const coord = mapBrowerEvent.coordinate;
    return (
      isPrimaryAction &&
      !checkIsVertexCoordinate(
        map,
        geom,
        coord,
        CHECK_POLYGON_VERTEX_COORD_TOLERANCE
      )
    );
  }

  let isBeingModified = false;
  let modificationHandle = null;
  let sides = null;
  let currentSideId = null;

  const modify = new Modify({
    features,
    condition: modifyCondition,
    insertVertexCondition: never,
    deleteCondition: never,
    style: function (feature) {
      modificationHandle = feature;
      const featureBeingModified = getFeatureBeingModified();
      const geom = featureBeingModified.getGeometry();
      const coord = modificationHandle.getGeometry().getFirstCoordinate();
      const isPolygonVertexCoord_ = checkIsVertexCoordinate(
        map,
        geom,
        coord,
        CHECK_POLYGON_VERTEX_COORD_TOLERANCE
      );
      const modificationHandleStyle = createDrawingStyle(map, {
        getPointerTip: () => 'Drag to resize.',
      })(feature);
      return isPolygonVertexCoord_ ? null : modificationHandleStyle;
    },
  });
  modify.on('modifystart', () => {
    isBeingModified = true;
    const feature = getFeatureBeingModified();
    sides = getSides(map, feature);
    const modificationStartPixel = map.getPixelFromCoordinate(
      modificationHandle.getGeometry().getFirstCoordinate()
    );
    currentSideId = getCurrentSideId(sides, modificationStartPixel);
  });
  modify.on('modifyend', () => {
    isBeingModified = false;
    modificationHandle = null;
    sides = null;
    currentSideId = null;
  });
  interactions.push(modify);

  const pointer = new Pointer({
    handleMoveEvent: function () {
      if (isBeingModified) {
        let leftSideId = currentSideId - 1;
        if (leftSideId === -1) {
          leftSideId = 3;
        }
        let rightSideId = currentSideId + 1;
        if (rightSideId === 4) {
          rightSideId = 0;
        }

        const endPixel = map.getPixelFromCoordinate(
          modificationHandle.getGeometry().getCoordinates()
        );

        const newSide = { ...sides[currentSideId] };
        if (newSide.hasOwnProperty('k')) {
          newSide['b'] = endPixel[1] - newSide['k'] * endPixel[0];
        } else {
          newSide['b'] = endPixel[0];
        }
        const leftSide = sides[leftSideId];
        const rightSide = sides[rightSideId];

        // Calculate the pixel of the cross point
        const newLeftVertexPixel = [];
        if (newSide.hasOwnProperty('k')) {
          if (leftSide.hasOwnProperty('k')) {
            const k1 = newSide['k'];
            const b1 = newSide['b'];
            const k2 = leftSide['k'];
            const b2 = leftSide['b'];
            newLeftVertexPixel.push((b1 - b2) / (k2 - k1));
            newLeftVertexPixel.push((k2 * b1 - k1 * b2) / (k2 - k1));
          } else {
            newLeftVertexPixel.push(leftSide['b']);
            newLeftVertexPixel.push(newSide['b']);
          }
        } else {
          // left side must be orthogonal to the new side
          newLeftVertexPixel.push(newSide['b']);
          newLeftVertexPixel.push(leftSide['b']);
        }

        const newRightVertexPixel = [];
        if (newSide.hasOwnProperty('k')) {
          if (rightSide.hasOwnProperty('k')) {
            // new side must cross right side
            const k1 = newSide['k'];
            const b1 = newSide['b'];
            const k2 = rightSide['k'];
            const b2 = rightSide['b'];
            newRightVertexPixel.push((b1 - b2) / (k2 - k1));
            newRightVertexPixel.push((k2 * b1 - k1 * b2) / (k2 - k1));
          } else {
            newRightVertexPixel.push(rightSide['b']);
            newRightVertexPixel.push(newSide['b']);
          }
        } else {
          // right side must be orthogonal to the new side
          newRightVertexPixel.push(newSide['b']);
          newRightVertexPixel.push(rightSide['b']);
        }

        const newLeftSideVertexCoord =
          map.getCoordinateFromPixel(newLeftVertexPixel);
        const newRightSideVertexCoord =
          map.getCoordinateFromPixel(newRightVertexPixel);

        const feature = getFeatureBeingModified();
        const newGeometry = feature.getGeometry().clone();
        newGeometry.applyTransform((coords) => {
          const mapping = SIDE_COORD_MAPPING[currentSideId];
          mapping.forEach((func) => {
            func(coords, newLeftSideVertexCoord, newRightSideVertexCoord);
          });
        });
        feature.setGeometry(newGeometry);
      }
    },
  });
  interactions.push(pointer);

  if (!draggingOnSidesEnabled) {
    modify.setActive(false);
    pointer.setActive(false);
  }

  return {
    interactions,
    selectFeature(feature) {
      transform.select(feature, true);
    },
    getFeature() {
      return transform.getFeatures().item(0);
    },
    activate(map) {
      interactions.forEach((interaction) => {
        map.addInteraction(interaction);
      });

      changeTransformStyle(transform);
    },
    destroy(map) {
      interactions.forEach((interaction) => {
        map.removeInteraction(interaction);
      });
    },
    checkIsWorking() {
      return !!modify.getOverlay().getSource().getFeatures().length;
    },
  };
}
