<script lang="ts" setup>
import Spinner from '@component-library/components/Spinner.vue';
import { Section } from '@component-library/gather';
const props = defineProps<{
  section: Section & { toggled?: boolean; loading?: boolean };
}>();

const emit = defineEmits(['toggleSection']);

function toggleSection() {
  emit('toggleSection', props.section);
}
</script>

<template>
  <tr class="section-title">
    <td
      class="text-start font-weight-medium position-sticky left-0 clickable"
      style="background: #2882bf !important; color: #ffffff; left: 0"
      colspan="100%"
      @click="toggleSection"
    >
      {{ section.label }}
      <i
        class="fas"
        :class="{
          'fa-chevron-up': section.toggled,
          'fa-chevron-down': !section.toggled,
        }"
      />
      <Spinner small v-if="section.loading" />
    </td>
  </tr>
</template>
