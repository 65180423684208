import { useToastStore } from '@component-library/store/toasts';
import Echo from 'laravel-echo';

const instance = new Echo({
  broadcaster: 'pusher',
  wsHost: import.meta.env.VITE_APP_ECHO_HOST,
  wssHost: import.meta.env.VITE_APP_ECHO_HOST,
  wsPort: import.meta.env.VITE_APP_ECHO_PORT,
  wssPort: import.meta.env.VITE_APP_ECHO_PORT,
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  encrypted: import.meta.env.VITE_APP_ECHO_SCHEME === 'https',
  forceTLS: import.meta.env.VITE_APP_ECHO_SCHEME === 'https',
  disableStats: true,
  enabledTransports:
    import.meta.env.VITE_APP_ECHO_SCHEME === 'https' ? ['wss'] : ['ws'],
  withoutInterceptors: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        window.axios
          .post('/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });

        window.Echo.channel('broadcast').listen(
          'BroadcastMessage',
          (notice) => {
            useToastStore().info(notice.message, {
              timeout: false,
              force: true,
            });
          }
        );
      },
    };
  },
});

export default function useEcho() {
  return instance;
}
