import store from '@/js/store';
import EventBus from '@component-library/EventBus';
import { SERVICE } from '@component-library/business-logic/mapping/layer/types';
import _debounce from 'lodash/debounce';
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer';
import { toSize } from 'ol/size';
import { DEFAULT_TILE_SIZE } from 'ol/tilegrid/common';
import { getUid } from 'ol/util';
import * as bl from '../../../business-logic';
import { getBasemapTitle } from '../../../business-logic/basemap';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { BasemapId } from '../../../lib/olbm/layer/basemap/types';
import createGoogleMapsLayer from '../../../lib/olbm/layer/service/google/createGoogleMapsLayer';
import { checkIsContours } from '../../../lib/olbm/layer/shape/utils';
import { NAMESPACE } from '../../../store';
import * as services from '../services';
import * as utils from '../utils';
import * as callout from './call-out';
import createExtentEditorLayer from './createExtentEditorLayer';

export function assignIdToLayer(layer) {
  getUid(layer);
}

export function getMaxZIndex() {
  return utils.MAXIMUM_NUMBER_OF_LAYERS - 1;
}

const notifyErrorOnLoadingServiceLayer = _debounce(
  (baseMapTitle, viewer, event) => {
    if (event && event.error) {
      console.error(event.error);
      const formattedError = Object.keys(event.error).reduce((acc, key) => {
        return acc + `${key}: ${event.error[key]}\n`;
      }, 'From: ' + baseMapTitle + ' ');
      viewer.$toastStore.error(formattedError);
    }
    console.error('An error occurred during loading the service layer.');
  },
  5000
);
const addListenersForServiceLayer = (map, serviceLayer) => {
  const baseMapTitle = getBasemapTitle(serviceLayer.options.index);
  serviceLayer.on('loadstart', () => {
    map.getContainer().startLoadingLayer(getUid(serviceLayer));
  });

  serviceLayer.on('loadend', () => {
    map.getContainer().endLoadingLayer(getUid(serviceLayer));
  });

  serviceLayer.on('loaderror', (event) => {
    notifyErrorOnLoadingServiceLayer(baseMapTitle, map.viewer, event);
  });
};

const LAYER_FILTER_CREATORS = {
  createFilterByType: (type) => (layer) => layer.options.type === type,
  createFilterByUid: (uid) => (layer) => getUid(layer) === uid,
  createFilterByDatabaseLayerId: (databaseLayerId) => (layer) =>
    layer.databaseLayerId === databaseLayerId,
  createFilterBySampleGroup: (sampleGroup) => (layer) => {
    const _sampleGroup = layer.getSampleGroup();
    return _sampleGroup === sampleGroup || _sampleGroup.id === sampleGroup.id;
  },
};

export default class LayerManager {
  constructor(map) {
    this.map = map;
  }
  isManagedType_(type) {
    return Object.values(LAYER_TYPES).indexOf(type) !== -1;
  }
  _checkType(type) {
    if (!this.isManagedType_(type)) {
      throw new Error(`The layer type "${type}" is unknown.`);
    }
  }
  isManagedLayer(layer) {
    return layer?.options && this.isManagedType_(layer.options.type);
  }
  isContours(layer) {
    const { type, usage } = layer.options;
    return checkIsContours(type, usage);
  }
  isEditableLayer(layer) {
    if (!this.isManagedLayer(layer) || this.map.getViewer().isViewOnly.value) {
      return false;
    }

    const { type: layerType } = layer.options;
    return (
      [
        LAYER_TYPES.RECTANGLE,
        LAYER_TYPES.CIRCLE,
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.ARROW,
        LAYER_TYPES.SITE_BOUNDARY,
        LAYER_TYPES.TEXT,
        LAYER_TYPES.CALL_OUT,
        LAYER_TYPES.IMAGE,
        LAYER_TYPES.SAMPLE,
        LAYER_TYPES.CHAINAGE,
        LAYER_TYPES.HEDGE,
      ].includes(layerType) || this.isContours(layer)
    );
  }
  isLayerBeingEdited(layer) {
    if (!this.isManagedLayer(layer)) {
      return false;
    }

    const { currentDrawingLayer, currentDrawingSample, currentOtherLayer } =
      this.map.getViewer();

    const { type: layerType } = layer.options;
    if (layerType === LAYER_TYPES.IMAGE) {
      // The image layer is handled as a drawing layer, however it is assigned to
      // the currentOtherLayer instead of the currentDrawingLayer.
      return layer === currentOtherLayer;
    } else if (layerType === LAYER_TYPES.SAMPLE) {
      return layer.checkHasSample(currentDrawingSample);
    } else if (this.getDrawingLayerTypes_().indexOf(layerType) !== -1) {
      return layer === currentDrawingLayer;
    } else {
      throw new Error(`The layer type ${layerType} is not supported.`);
    }
  }
  getAllLayers() {
    return this.map
      .getLayers()
      .getArray()
      .filter((layer) => this.isManagedLayer(layer));
  }
  findLayers(layers, filterBy) {
    return layers.filter((l) => filterBy(l));
  }
  findLayer(layers, filterBy) {
    const [layer] = this.findLayers(layers, filterBy);
    return layer;
  }
  findLayersByType(type) {
    this._checkType(type);
    const layers = this.getAllLayers();
    return this.findLayers(
      layers,
      LAYER_FILTER_CREATORS.createFilterByType(type)
    );
  }
  findLayersByTypes(types) {
    const layers = this.getAllLayers();
    return types.reduce((accumulator, type) => {
      this._checkType(type);
      return [
        ...accumulator,
        ...this.findLayers(
          layers,
          LAYER_FILTER_CREATORS.createFilterByType(type)
        ),
      ];
    }, []);
  }
  findLayerByUid(uid) {
    const layers = this.getAllLayers();
    return this.findLayer(layers, LAYER_FILTER_CREATORS.createFilterByUid(uid));
  }
  findLayerByDatabaseLayerId(databaseLayerId) {
    const layers = this.getAllLayers();
    return this.findLayer(
      layers,
      LAYER_FILTER_CREATORS.createFilterByDatabaseLayerId(databaseLayerId)
    );
  }
  findSampleLayerBySampleGroup(sampleGroup) {
    const layers = this.getSampleLayers();
    return this.findLayer(
      layers,
      LAYER_FILTER_CREATORS.createFilterBySampleGroup(sampleGroup)
    );
  }
  findLayerBySampleId(sampleId) {
    const sample = NAMESPACE.getGetter(store, 'getSampleByIdEx')(sampleId);
    const result = sample?.sample_group
      ? this.findSampleLayerBySampleGroup(sample.sample_group)
      : null;
    if (result) {
      return result;
    }

    const layerNode = NAMESPACE.getGetter(
      store,
      'getLayerBySampleId'
    )(sampleId);
    return layerNode ? this.findLayerByDatabaseLayerId(layerNode.id) : null;
  }
  findLayerByFeature(feature) {
    if (!feature) {
      return null;
    }

    const layers = this.getAllLayers();
    const { layerUid: uid } = feature;
    return uid
      ? this.findLayer(layers, LAYER_FILTER_CREATORS.createFilterByUid(uid))
      : this.findLayer(layers, (layer) => {
          const isCandidateLayer = [
            LAYER_TYPES.RECTANGLE,
            LAYER_TYPES.CIRCLE,
            LAYER_TYPES.POLYGON,
            LAYER_TYPES.POLYLINE,
            LAYER_TYPES.ARROW,
            LAYER_TYPES.SITE_BOUNDARY,
            LAYER_TYPES.TEXT,
            LAYER_TYPES.CALL_OUT,
            LAYER_TYPES.IMAGE,
            LAYER_TYPES.SAMPLE,
            LAYER_TYPES.FEATURE_COLLECTION,
            LAYER_TYPES.SERVICE,
            LAYER_TYPES.SAMPLE_POPUP_CONNECTORS,
            LAYER_TYPES.BUFFER,
            LAYER_TYPES.CHAINAGE,
            LAYER_TYPES.HEDGE,
          ].includes(layer.options.type);
          return isCandidateLayer && layer.hasFeature(feature);
        });
  }
  findBufferLayersByBoundLayerId(boundLayerId) {
    const bufferLayers = this.findLayersByType(LAYER_TYPES.BUFFER);
    return bufferLayers.filter((item) =>
      item.options.boundLayerIds.includes(boundLayerId)
    );
  }
  addLayer(layer) {
    const type = layer.options?.type;
    this._checkType(type);

    if (this.isBasemapLayer(layer)) {
      let isAdded = false;
      const count = this.getBasemapLayers().length;
      if (!count) {
        this.map.getLayers().insertAt(0, layer);
        isAdded = true;
      } else if (count === 1) {
        const lowestLayer = this.map.getLayers().item(0);
        if (
          lowestLayer.options.type === LAYER_TYPES.BASEMAP_IMAGE ||
          lowestLayer.options.type !== layer.options.type ||
          lowestLayer.options.index !== layer.options.index
        ) {
          this.removeBasemap();
          this.map.getLayers().insertAt(0, layer);
          isAdded = true;
        } else if (layer.options.index === BasemapId.NEARMAP) {
          const isSwipeVisible = NAMESPACE.getState(store, 'isSwipeVisible');
          if (isSwipeVisible) {
            const position = layer.get('position');
            this.map.getLayers().insertAt(position, layer);
          } else {
            this.removeBasemap();
            this.map.getLayers().insertAt(0, layer);
          }
          isAdded = true;
        }
      } else if (count === 2) {
        this.removeBasemap();
        this.map.getLayers().insertAt(0, layer);
        isAdded = true;
      } else {
        throw `The count of basemap layers should not exceed 2.`;
      }

      if (isAdded && layer.options.type === LAYER_TYPES.BASEMAP_SERVICE) {
        addListenersForServiceLayer(this.map, layer);
      }

      if (
        isAdded &&
        [
          BasemapId.GOOGLE_MAPS_ROADMAP,
          BasemapId.GOOGLE_MAPS_SATELLITE,
        ].includes(layer.options.index)
      ) {
        this.map.controlManager.showGoogleLogo();
      }
    } else if (type === LAYER_TYPES.BUFFER) {
      // Put buffer under its bound layer.
      const { boundLayerIds } = layer.options;
      const boundLayers = boundLayerIds.map((item) =>
        this.findLayerByDatabaseLayerId(item)
      );
      const pos = Math.min(
        ...boundLayers.map((item) =>
          this.map.getLayers().getArray().indexOf(item)
        )
      );
      this.map.getLayers().insertAt(pos, layer);
    } else {
      this.map.addLayer(layer);
    }

    if (type == SERVICE) {
      this.map.addLayerToSelectServiceLayerFeature(layer);
    }
  }
  removeLayer(layer) {
    const type = layer.options?.type;
    this._checkType(type);
    this.map.removeLayer(layer);
    this.map.getContainer().endLoadingLayer(getUid(layer));
  }
  removeLayersByType(type) {
    this._checkType(type);

    const layers = this.findLayersByType(type);
    for (const layer of layers) {
      this.removeLayer(layer);
    }
  }
  removeLayersByTypes(types) {
    for (const type of types) {
      this.removeLayersByType(type);
    }
  }
  drawBuiltinCallout(sample, contentType) {
    const { selectedFigure, visualReportViewer } = this.map.getViewer();

    if (
      visualReportViewer ||
      this.checkIsBuiltinCalloutVisible(sample.id, contentType)
    ) {
      return;
    }

    if (
      contentType === callout.constants.BUILTIN_CALLOUT_CONTENT_TYPES.ENVIRO
    ) {
      if (
        !bl.figure.checkIsSpecificChemicalPlan(selectedFigure) ||
        NAMESPACE.getGetter(store, 'checkIsCcHidden')(sample) ||
        !callout.utils.getEnviroTable(this.map, sample)
      ) {
        return;
      }
    } else if (
      contentType === callout.constants.BUILTIN_CALLOUT_CONTENT_TYPES.GATHER
    ) {
      if (
        !bl.figure.checkIsGatherCalloutEnabled(selectedFigure) ||
        NAMESPACE.getGetter(store, 'checkIsGcHidden')(sample) ||
        !callout.utils.getGatherTable(this.map, sample)
      ) {
        return;
      }
    }

    const { connector, options } = callout.utils.getBuiltinCalloutConfig(
      this.map,
      sample,
      contentType
    );
    const builtinCallout = callout.createLayer(this.map, connector);
    builtinCallout.applyOptions(options);
    this.addLayer(builtinCallout);
  }
  drawBuiltinCallouts(sample) {
    this.drawBuiltinCallout(
      sample,
      callout.constants.BUILTIN_CALLOUT_CONTENT_TYPES.ENVIRO
    );
    this.drawBuiltinCallout(
      sample,
      callout.constants.BUILTIN_CALLOUT_CONTENT_TYPES.GATHER
    );
  }
  removeBuiltinCallout(sample, contentType) {
    const builtinCallout = this.getAllCallouts().find((c) => {
      const { isBuiltin, connectedTarget } = c.options;
      return (
        isBuiltin &&
        connectedTarget.id === sample.id &&
        c.options.contentType === contentType
      );
    });
    if (builtinCallout) {
      this.removeLayer(builtinCallout);
    }
  }
  removeBuiltinCallouts(sample) {
    this.removeBuiltinCallout(
      sample,
      callout.constants.BUILTIN_CALLOUT_CONTENT_TYPES.ENVIRO
    );
    this.removeBuiltinCallout(
      sample,
      callout.constants.BUILTIN_CALLOUT_CONTENT_TYPES.GATHER
    );
  }
  getBasemapLayerTypes_() {
    return [LAYER_TYPES.BASEMAP_SERVICE, LAYER_TYPES.BASEMAP_IMAGE];
  }
  isBasemapLayer(layer) {
    return (
      this.isManagedLayer(layer) &&
      this.getBasemapLayerTypes_().indexOf(layer.options.type) !== -1
    );
  }
  getBasemapLayers() {
    const types = this.getBasemapLayerTypes_();
    return this.findLayersByTypes(types);
  }
  updateBasemapOpacity(opacity) {
    const basemapLayers = this.getBasemapLayers();

    opacity = parseFloat(opacity);
    basemapLayers.forEach((basemapLayer) => {
      basemapLayer.setOpacity(opacity);
    });
  }
  removeBasemap() {
    const types = this.getBasemapLayerTypes_();
    this.removeLayersByTypes(types);

    const { controlManager } = this.map;
    const googleLogo = controlManager.getGoogleLogo();
    if (googleLogo) {
      controlManager.hideGoogleLogo();
    }
  }
  getDrawingLayerTypes_() {
    return [
      LAYER_TYPES.RECTANGLE,
      LAYER_TYPES.CIRCLE,
      LAYER_TYPES.POLYGON,
      LAYER_TYPES.POLYLINE,
      LAYER_TYPES.ARROW,
      LAYER_TYPES.SITE_BOUNDARY,
      LAYER_TYPES.TEXT,
      LAYER_TYPES.CALL_OUT,
      LAYER_TYPES.IMAGE,
      LAYER_TYPES.SERVICE,
      LAYER_TYPES.FEATURE_COLLECTION,
      LAYER_TYPES.BUFFER,
      LAYER_TYPES.CHAINAGE,
      LAYER_TYPES.HEDGE,
    ];
  }
  getDrawingLayers() {
    const types = this.getDrawingLayerTypes_();
    return this.findLayersByTypes(types);
  }
  clearDrawingLayers() {
    const types = this.getDrawingLayerTypes_();
    this.removeLayersByTypes(types);
  }
  generateGeoJSONOfDrawingLayers(drawingLayers) {
    const result = utils.toGeoJSON(this.map, []);

    drawingLayers.forEach((dl) => {
      const geoJSON = dl.toGeoJSON();
      if (Array.isArray(geoJSON.features)) {
        geoJSON.features.forEach((f) =>
          result.features.push(
            f.geometry.type === 'LineString'
              ? utils.lineToMultiLine(this.map, f)
              : f
          )
        );
      } else {
        result.features.push(
          geoJSON.geometry.type === 'LineString'
            ? utils.lineToMultiLine(this.map, geoJSON)
            : geoJSON
        );
      }
    });

    return result;
  }
  getTextLayers() {
    return this.findLayersByType(LAYER_TYPES.TEXT);
  }
  getAllCallouts() {
    return this.findLayersByType(LAYER_TYPES.CALL_OUT);
  }
  getSampleLayers() {
    return this.findLayersByType(LAYER_TYPES.SAMPLE);
  }
  clearSampleLayers() {
    this.removeLayersByType(LAYER_TYPES.SAMPLE_MARKER_LABEL_CONNECTOR);
    this.removeLayersByType(LAYER_TYPES.SAMPLE);
    this.getAllBuiltinCallouts().forEach((bc) => this.removeLayer(bc));
  }
  generateGeoJSONOfSampleLayers() {
    const geoJSON = utils.toGeoJSON(this.map, []);
    const sampleLayers = this.getSampleLayers();

    sampleLayers.forEach((item) => {
      const { features: gFeatures } = item.toGeoJSON();
      geoJSON.features.push(...gFeatures);
    });

    return geoJSON;
  }
  getSiteBoundaryLayers() {
    return this.findLayersByType(LAYER_TYPES.SITE_BOUNDARY);
  }
  clearSiteBoundaryIndicatorLayers() {
    this.removeLayersByType(LAYER_TYPES.SITE_BOUNDARY_INDICATOR);
  }
  clearSiteBoundaryLayers() {
    this.removeLayersByType(LAYER_TYPES.SITE_BOUNDARY);
  }
  calculateBoundsOfSiteBoundaryLayers(padding = 0.2) {
    const extents = [];

    this.getSiteBoundaryLayers().forEach((layer) => {
      extents.push(layer.getSource().getExtent());
    });
    const extent = utils.mergeExtents(...extents);

    return utils.extentToBounds(
      extent,
      this.map.getView().getProjection(),
      padding
    );
  }
  clearHighlightedLayers() {
    this.removeLayersByType(LAYER_TYPES.HIGHLIGHTED);
  }
  getImageLayers() {
    return this.findLayersByType(LAYER_TYPES.IMAGE);
  }
  getParcelsLayer() {
    return this.findLayersByType(LAYER_TYPES.PARCELS)[0];
  }
  updateLayerLoadingStatus() {
    const allLayers = this.getAllLayers();
    const viewer = this.map.getViewer();
    allLayers.forEach((layer) => {
      const minZoom = layer.getMinZoom();
      const maxZoom = layer.getMaxZoom();
      const zoom = this.map.getZoom();
      if (zoom < minZoom || zoom > maxZoom) {
        viewer.endLoadingLayer(getUid(layer));
      }
    });
  }
  getMergedExtent(layers) {
    const extents = [];
    layers.forEach((layer) => {
      if (typeof layer.getBounds === 'function') {
        const bounds = layer.getBounds(0);
        const extent = utils.boundsToExtent(
          bounds,
          this.map.getView().getProjection()
        );
        extents.push(extent);
      }
    });

    return extents.length > 0
      ? utils.mergeExtents(...extents)
      : this.map.getView().calculateExtent();
  }
  checkIsTextRequired(layer) {
    const { type: layerType } = layer.options;
    if (layerType === LAYER_TYPES.TEXT) {
      return true;
    } else if (layerType === LAYER_TYPES.CALL_OUT) {
      return !layer.options.isBuiltin;
    }

    return false;
  }
  generateTextPlaceholder(layer) {
    const { type } = layer.options;

    let layers = this.findLayersByType(type);
    let prefix = 'Text';

    if (type === LAYER_TYPES.CALL_OUT) {
      layers = layers.filter((item) => !item.options.isBuiltin);
      prefix = 'Call-out';
    }

    const existingTexts = layers.map((layer) => layer.options.text || '');
    return bl.feature.generateTextPlaceholder(existingTexts, prefix);
  }
  getEsriImageryLayerInfo(options) {
    const { hasLabels, maxImageSize } = options;

    const mapSize = this.map.getSize();
    const ratio = 1.5;
    const imageSize = [mapSize[0] * ratio, mapSize[1] * ratio];

    let maxImageWidth = Number.MAX_SAFE_INTEGER;
    if (typeof maxImageSize?.width === 'number') {
      maxImageWidth = maxImageSize.width;
    }

    let maxImageHeight = Number.MAX_SAFE_INTEGER;
    if (typeof maxImageSize?.height === 'number') {
      maxImageHeight = maxImageSize.height;
    }

    const isMaxImageSizeExceeded =
      imageSize[0] > maxImageWidth || imageSize[1] > maxImageHeight;
    const isTileServiceForced = !hasLabels || isMaxImageSizeExceeded;

    const tileSize = isMaxImageSizeExceeded
      ? toSize(Math.min(maxImageWidth, maxImageHeight))
      : toSize(DEFAULT_TILE_SIZE);

    return {
      isTileServiceForced,
      tileSize,
    };
  }
  refreshEsriFeatureServerLayers() {
    this.findLayersByType(LAYER_TYPES.SERVICE)
      .filter((layer) => layer.options.isFeatureLayer)
      .forEach((featureLayer) => {
        // No need to refresh feature layers which don't support PBF.
        if (!featureLayer.options.pbfSupported) {
          return;
        }
        featureLayer.getSource().refresh();
      });
  }
  refreshEsriImageryLayers() {
    const viewer = this.map.getViewer();
    this.findLayersByType(LAYER_TYPES.SERVICE)
      .filter(
        (layer) =>
          layer.options.isDynamicMapLayer || layer.options.isImageMapLayer
      )
      .forEach((layer) => {
        if (viewer.currentOtherLayer?.id === layer.databaseLayerId) {
          return;
        }

        const { isRerenderForced } = layer.options;
        if (isRerenderForced) {
          EventBus.$emit('rerenderLayer', layer.databaseLayerId);
          return;
        }

        // No need to refresh layers which use the Map Tile API.
        const { mapTileConfig } = layer.options;
        if (mapTileConfig) {
          return;
        }

        const { isTileServiceForced } = this.getEsriImageryLayerInfo(
          layer.options
        );
        if (
          (isTileServiceForced && layer instanceof ImageLayer) ||
          (!isTileServiceForced && layer instanceof TileLayer)
        ) {
          EventBus.$emit('rerenderLayer', layer.databaseLayerId);
        }
      });
  }
  refreshEsriVectorTileServerLayers() {
    this.findLayersByTypes([LAYER_TYPES.SERVICE, LAYER_TYPES.BASEMAP_SERVICE])
      .filter((layer) => layer.options.isVectorTileLayer)
      .forEach((layer) => {
        layer.dispatchEvent('refresh');
      });
  }
  updateExtentEditorLayer(extent, readonly = false) {
    const [extentEditorLayer] = this.findLayersByType(
      LAYER_TYPES.EXTENT_EDITOR
    );

    if (!extentEditorLayer) {
      return;
    }

    extentEditorLayer.remove();
    const newExtentEditorLayer = createExtentEditorLayer(
      this.map,
      extent,
      readonly
    );
    this.addLayer(newExtentEditorLayer);
  }
  refreshExtentEditorLayer() {
    const [extentEditorLayer] = this.findLayersByType(
      LAYER_TYPES.EXTENT_EDITOR
    );

    if (!extentEditorLayer) {
      return;
    }

    const { serviceLayerVisibleExtentOption } = this.map.getViewer();
    if (
      serviceLayerVisibleExtentOption ===
      services.VISIBLE_EXTENT_OPTIONS.CURRENT_VIEW_EXTENT
    ) {
      const extent = this.map.getView().calculateExtent();
      this.updateExtentEditorLayer(extent, true);
    } else {
      extentEditorLayer.refresh();
    }
  }
  getServiceLayerVisibleExtentInEpsg4326() {
    const [extentEditorLayer] = this.findLayersByType(
      LAYER_TYPES.EXTENT_EDITOR
    );
    const extent = extentEditorLayer.getManagedExtent();
    const projection = this.map.getView().getProjection();
    return utils.transformExtent(extent, projection, 'EPSG:4326');
  }
  createGoogleMapsLayer(options) {
    return createGoogleMapsLayer(this.map, options);
  }
  createEsriLayer(esriType, options) {
    const serviceLayer = services.esri[esriType](this.map, options);
    addListenersForServiceLayer(this.map, serviceLayer);
    return serviceLayer;
  }
  createOgcOpenGisLayer(serviceSubtype, options) {
    let serviceLayer;
    switch (serviceSubtype) {
      case services.OGC_OPENGIS_SERVICE_SUBTYPES.WFS:
        serviceLayer = services.OgcOpenGisWfs.createLayer(this.map, options);
        break;
      case services.OGC_OPENGIS_SERVICE_SUBTYPES.WMS:
        serviceLayer = services.OgcOpenGisWms.createLayer(this.map, options);
        break;
      case services.OGC_OPENGIS_SERVICE_SUBTYPES.WMTS:
        serviceLayer = services.OgcOpenGisWmts.createLayer(this.map, options);
        break;
    }
    serviceLayer.applyOptions(options);
    addListenersForServiceLayer(this.map, serviceLayer);
    return serviceLayer;
  }
  createXyzTilesServiceLayer(options) {
    const serviceLayer = services.createXyzTilesServiceLayer(this.map, options);
    addListenersForServiceLayer(this.map, serviceLayer);
    return serviceLayer;
  }
  checkIsBuiltinCallout(layer) {
    const { type, isBuiltin } = layer.options;
    return type === LAYER_TYPES.CALL_OUT && isBuiltin;
  }
  checkIsBuiltinCalloutVisible(sampleId, contentType) {
    return this.getAllBuiltinCallouts().some(
      (bc) =>
        bc.options.contentType === contentType &&
        bc.options.connectedTarget?.id === sampleId
    );
  }
  getAllBuiltinCallouts() {
    return this.getAllCallouts().filter((item) =>
      this.checkIsBuiltinCallout(item)
    );
  }
  calculateZIndexOfBufferLayer(boundLayerIds, bufferType) {
    const boundLayers = boundLayerIds
      .map((item) => this.findLayerByDatabaseLayerId(item))
      .filter((item) => !!item);
    const zs = boundLayers.map((item) => item.getZIndex());
    return bufferType === bl.analysis.buffer.BUFFER_TYPES.INVERSE
      ? Math.max(...zs) + 1
      : Math.min(...zs) - 1;
  }
  refreshCalloutMaps() {
    const viewer = this.map.getViewer();
    this.getAllMapCallouts().forEach((mc) => {
      const {
        calloutMap,
        options: {
          mapData: { centerLatLng, scale },
        },
      } = mc;
      if (calloutMap) {
        calloutMap.updateSize();

        const view = calloutMap.getView();
        const projection = view.getProjection();
        const center = utils.latLngToCoordinate(centerLatLng, projection);
        const resolution = utils.scaleToResolution(
          projection,
          scale / viewer.figureLayout.zoom,
          center
        );
        view.setResolution(resolution);
        view.setCenter(center);
      }
    });
  }
  refreshAllMapCalloutSampleLayers() {
    const viewer = this.map.getViewer();
    this.getAllMapCallouts().forEach((mc) => {
      mc.calloutMap?.refreshSampleLayers();
    });
  }
  getPolylineLayersInViewport() {
    const extent = this.map.getView().calculateExtent();
    const polylines = this.findLayersByType(LAYER_TYPES.POLYLINE);
    return polylines.filter((polyline) =>
      polyline.getFirstFeature().getGeometry().intersectsExtent(extent)
    );
  }
  getAllMapCallouts() {
    return this.getAllCallouts().filter(
      (callout) => callout.options.usage === bl.tool.call_out.Usage.ShowMap
    );
  }
  getSampleLayersFromMapCallouts(sampleGroupId) {
    return this.getAllMapCallouts().reduce((accu, mc) => {
      // The calloutMap was created asynchronously so it could be null.
      const sampleLayers = mc.calloutMap?.layerManager.getSampleLayers() ?? [];
      const sl = sampleLayers.find(
        (sl) => sl.getSampleGroup().id === sampleGroupId
      );
      if (sl) {
        accu.push(sl);
      }
      return accu;
    }, []);
  }
  deleteFeature({ id, layerId }) {
    const layer = this.findLayerByDatabaseLayerId(layerId);
    const source = layer.getSource();
    const feature = source.getFeatures().find((f) => f.getId() === id);
    source.removeFeature(feature);
  }
}
