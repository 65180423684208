import EventBus from '@component-library/EventBus';
import { checkIsNewStylingRule } from '@component-library/business-logic/mapping/styling-rule';
import { produce } from 'immer';
import Vue from 'vue';
import * as bl from '../business-logic';
import { LAYER_TYPES } from '../business-logic/layer';
import type { FigureStylingRuleLocator } from '../business-logic/styling/figure-styling-rule';
import {
  deleteOneOfFigureStylingRules,
  updateOneOfFigureStylingRules,
} from '../business-logic/styling/figure-styling-rule';
import TreeView from '../modules/TreeView';
import { NAMESPACE } from './helpers';
import { default as getDefaultState } from './state';

export function resetMapsState(state) {
  // Merge rather than replace so we don't lose observers
  // https://github.com/vuejs/vuex/issues/1118
  const ds = getDefaultState();
  Object.keys(ds).forEach((item) => {
    state[item] = ds[item];
  });
}

/**
 * Layers
 */
export function reorderAllLayers(this: any, state, treeModel) {
  const layers: any[] = [];

  // re-order db layers to match treeview ordering
  treeModel.forEach((node) => {
    const layer = NAMESPACE.getGetter(this, 'getLayerById')(node.id);
    const children: any[] = [];

    layer.state = node.states;

    node.children.forEach((item) => {
      const child = NAMESPACE.getGetter(this, 'getLayerById')(item.data.id);
      child.state = item.states;
      children.push(child);
    });

    layer.children = children;

    layers.push(layer);
  });

  state.allLayers = layers;

  EventBus.$emit('rearrangeLayerOrder', state.allLayers);
}

export function setIsLoadingLayers(state, value) {
  state.isLoadingLayers = value;
}

export function initAllLayers(
  state,
  { layers, poly_gather_samples: polyGatherSamples }
) {
  state.polyGatherSamples = polyGatherSamples;
  state.allLayers = TreeView.prepareDBLayersForTree(layers);
}

export function addNewLayer(this: any, state, layer) {
  if (layer.folder_index != undefined) {
    state.allLayers.splice(layer.folder_index, 0, layer);
    return;
  }

  const { type, isBuiltin } = layer.data.properties;
  if (
    type === LAYER_TYPES.BUFFER ||
    (type === LAYER_TYPES.CALL_OUT && isBuiltin)
  ) {
    layer.state = {
      ...(layer.state ?? {}),
      visible: false,
    };
  }

  const parent = layer.parent_id
    ? NAMESPACE.getGetter(this, 'getLayerById')(layer.parent_id)
    : undefined;
  const layers = parent ? parent.children : state.allLayers;
  layers.splice(0, 0, layer);
}

export function removeLayer(this: any, state, layer_id) {
  let parentLevelLayerIndex = state.allLayers.findIndex(
    (l) => l.id == layer_id
  );

  if (parentLevelLayerIndex != -1) {
    state.allLayers.splice(parentLevelLayerIndex, 1);
    return;
  }

  let parentLayer = NAMESPACE.getGetter(this, 'getParentLayerById')(layer_id);

  if (!parentLayer || !parentLayer.children) {
    return;
  }

  let childLevelLayerIndex = parentLayer.children.findIndex(
    (l) => l.id == layer_id
  );

  parentLayer.children.splice(childLevelLayerIndex, 1);
}

export function updateIndividualLayer(this: any, state, layer) {
  let foundLayer = NAMESPACE.getGetter(this, 'getLayerById')(layer.id);

  if (!foundLayer) {
    return;
  }

  Object.keys(layer).map((p) => {
    foundLayer[p] = layer[p];
  });

  if (layer.data?.properties?.type === LAYER_TYPES.SAMPLE_GROUP) {
    const { properties, marker_identifier } = layer.data;
    const { geojson } = foundLayer;
    const newGeojson = JSON.stringify({ ...JSON.parse(geojson), properties });
    foundLayer.geojson = newGeojson;
    foundLayer.marker_identifier = marker_identifier;
  }
}

export function setCurrentShapeProperties(state, properties) {
  state.shapeProperties = properties;
}

export function resetCurrentShapeProperties(state) {
  if (!state.shapeProperties) {
    return;
  }

  state.shapeProperties['type'] = null;
  state.shapeProperties['title'] = null;
}

export function setPreviousSampleProperties(state, properties) {
  state.previousSampleProperties = properties;
}

export function setShapeProperty(state, data) {
  setCurrentShapeProperties(state, {
    ...state.shapeProperties,
    ...data,
  });
}

export function setEsriToken(state, token) {
  state.esriToken = token;
}

export function setIntegrations(state, integrations) {
  state.integrations = integrations;
}

export function updateLayerTextById(this: any, state, data) {
  let layer = NAMESPACE.getGetter(this, 'getLayerById')(data.id);

  if (!layer) {
    return;
  }

  layer.text = data.text;
  layer.data = { ...layer.data, renderer: data.renderer };
}

export function setIsUpdatingLayer(state, value) {
  if (value) {
    if (state._isUpdatingLayerCounter === 0) {
      state.isUpdatingLayer = value;
    }
    state._isUpdatingLayerCounter++;
  } else {
    state._isUpdatingLayerCounter--;
    if (state._isUpdatingLayerCounter === 0) {
      state.isUpdatingLayer = value;
    }
  }
}

/**
 * Samples
 */
export function setIsLoadingSamples(state, value) {
  state.isLoadingSamples = value;
}

export function setLoadingSamplesProgress(state, value) {
  state.loadingSamplesProgress = value;
}

export function clearAllSamples(state) {
  state.allSamples = [];
}

export function addNewSample(state, sample) {
  if (!sample.sample_group) {
    sample.sample_group = TreeView.searchTree(state.allLayers, (node) => {
      return node.data.properties && node.data.properties.default;
    });
  }

  state.allSamples.splice(0, 0, sample);
}

export function deleteSampleByIndex(state, index) {
  state.allSamples.splice(index, 1);
}

export function replaceSample(state, nextSample) {
  const index = state.allSamples.findIndex((item) => item.id === nextSample.id);

  state.allSamples.splice(index, 1, nextSample);
}

export function buildSampleGroupTree(state, shouldSkipSampleNodes) {
  const { allLayers, allSamples } = state;
  TreeView.buildSampleGroupTree(allLayers, allSamples, shouldSkipSampleNodes);
}

/**
 * Figures
 */
export function setFigureIdToSelect(state, figure_id) {
  state.figureIdToSelect = figure_id;
}

export function setIsLoadingFigures(state, value) {
  state.isLoadingFigures = value;
}

export function initAllFigures(state, { figures: allFigures, last_figure_id }) {
  state.allFigures = allFigures.map((item) => bl.figure.preprocessFigure(item));

  if (state.figureIdToSelect) {
    state.selectedFigure = state.allFigures.find(
      (f) => f.id == state.figureIdToSelect
    );
    return;
  }

  const figure =
    (!!last_figure_id &&
      state.allFigures.find((figure) => figure.id === last_figure_id)) ||
    state.allFigures.filter((figure) => !figure.is_basemap)[0];

  state.selectedFigure = figure;
}

export function selectFigure(state, figure) {
  state.selectedFigure = figure;
}

export function modifyFigure(state, figure) {
  let currentFigure = state.allFigures.find((f) => f.id == figure.id);

  if (!currentFigure) {
    state.allFigures.push(figure);

    let newFigure = state.allFigures[state.allFigures.length - 1];

    state.selectedFigure = newFigure;

    EventBus.$emit('changeFigure', newFigure);
    return;
  }

  Object.keys(figure).forEach((key) => {
    currentFigure[key] = figure[key];
  });
}

export function modifyFigureColumn(state, { column, value }) {
  state.selectedFigure[column] = value;
}

export function deleteFigure(state, figure_id) {
  let figureIndex = state.allFigures.findIndex((f) => f.id == figure_id);

  if (figureIndex == -1) {
    return;
  }

  state.allFigures.splice(figureIndex, 1);

  // if deleted current figure, then default to first figure
  if (figure_id == state.selectedFigure.id) {
    state.selectedFigure = state.allFigures[0];

    EventBus.$emit('changeFigure', state.selectedFigure);
  }
}

export function modifyFigureLayer(this: any, state, { layer_id, key, value }) {
  let layer = NAMESPACE.getGetter(this, 'getLayerById')(layer_id);

  layer[key] = value;
}

export function initGlobalSettings(state, settings) {
  state.globalSettings = settings;
}

export function setFigureStylingRules(state, figureStylingRules) {
  state.figureStylingRules = figureStylingRules;
}

export function modifyGlobalSettings(state, settings) {
  if (!state.globalSettings) {
    state.globalSettings = settings;
    return;
  }

  Object.keys(settings).forEach((key) => {
    state.globalSettings[key] = settings[key];
  });
}

/**
 * Additional Data Mutations
 */
export function initAllScenarios(state, scenarios) {
  state.allScenarios = scenarios;
}

export function initAllScenarioStyles(state, styles) {
  state.allScenarioStyles = styles;
}

export function initScenarioSet(state, scenarioSet) {
  state.scenarioSet = scenarioSet;
}

export function initAllChemicals(state, allChemicals) {
  state.allChemicals = allChemicals;
}

export function initAllExceedances(state, allExceedances) {
  state.allExceedances = allExceedances;
}

export function initAllChemicalResults(state, allResults) {
  Vue.set(state.selectedFigure, 'results', allResults);
}

/**
 * Other Mutations
 */
export function setBasemapRevisionDate(state, date) {
  state.basemapRevisionDate = date;
}

export function setHighlightedLayerId(this: any, state, layer) {
  if (!layer) {
    state.highlightedLayerId = null;
    return;
  }

  state.highlightedLayerId = layer.id;

  if (layer.parent_id) {
    let folderLayer = NAMESPACE.getGetter(
      this,
      'getLayerById'
    )(layer.parent_id);
    folderLayer.state = {
      expanded: true,
    };
  }
}

export function setHasLoadedMapData(state, value) {
  state.hasLoadedMapData = value;
}

export function updateClientLayerOrdering(state, { ordering, section_id }) {
  ordering.forEach((layer) => {
    let sLayer = state.allLayers.find((l) => l.id == layer.id);

    sLayer.section_id = section_id;
    sLayer.order = layer.order;
  });
}

export function setSelectedLayer(state, layer) {
  state.selectedLayer = layer;
}

export function setSelectedServiceLayerFeature(state, layer) {
  state.selectedServiceLayerFeature = layer;
}

export function setAddMultipleFeaturesEnabled(state, value) {
  state.addMultipleFeaturesEnabled = value;
}

export function addAmfSessionFeatureId(state, value) {
  state.amfSessionFeatureIds.push(value);
}

export function clearAmfSessionFeatureIds(state) {
  state.amfSessionFeatureIds = [];
}

export function setAmfSessionCancelChoice(state, value) {
  state.amfSessionCancelChoice = value;
}

export function setIsAddingFeature(state, value) {
  state.isAddingFeature = value;
}

export function setConfirmData(state, value) {
  state.confirmData = value;
}

export function setServiceLayerVisibleExtentOption(state, value) {
  state.serviceLayerVisibleExtentOption = value;
}

export function setFigurePrintLayouts(state, value) {
  state.figurePrintLayouts = value;
}

export function setImageEditMethodCode(state, value) {
  state.imageEditMethodCode = value;
}

export function setSampleIdsBeingDeleted(state, value) {
  state.sampleIdsBeingDeleted = value;
}

export function setSelectedSampleScopeId(state, value) {
  state.selectedSampleScopeId = value;
}

export function setSampleBeingRenamed(state, value) {
  state.sampleBeingRenamed = value;
}

export function setIsSwipeVisible(state, value) {
  state.isSwipeVisible = value;
}

export function setGatherApps(state, gatherApps) {
  state.gatherApps = gatherApps;
}

export function setSidebarWidth(state, value) {
  state.sidebarWidth = value;
}

export function setLayerSearchQuery(state, value) {
  state.layerSearchQuery = value;
}

// Mutations for styling rules
export function setTempFigureStylingRules(
  state,
  value: bl.styling.figure_styling_rule.FigureStylingRule[]
) {
  state.tempFigureStylingRules = value;
}

export function addNewFigureStylingRule(
  state,
  newFigureStylingRule: bl.styling.figure_styling_rule.FigureStylingRule
) {
  if (!checkIsNewStylingRule(newFigureStylingRule)) {
    return;
  }

  state.tempFigureStylingRules.push(newFigureStylingRule);
}

export function updateOneOfTempFigureStylingRules(
  state,
  payload: bl.styling.figure_styling_rule.UpdateOneOfFigureStylingRulesPayload
) {
  updateOneOfFigureStylingRules(state.tempFigureStylingRules, payload);
}

export function deleteOneOfTempFigureStylingRules(
  state,
  locator: FigureStylingRuleLocator
) {
  deleteOneOfFigureStylingRules(state.tempFigureStylingRules, locator);
}

export function setActiveTempFigureStylingRuleLocator(
  state,
  value: FigureStylingRuleLocator | undefined
) {
  state.activeTempFigureStylingRuleLocator = value;
}

export function setIsReloadNeeded(state, value) {
  state.isReloadNeeded = value;
}

export function updateFigureSettings(
  state,
  payload: bl.figure.UpdateFigureSettingsPayload
) {
  state.figureSettings = produce(state.figureSettings, payload);
}

export function setFigureExportingAction(state, value) {
  state.figureExportingAction = value;
}

export function setHasTooManySamplesInViewportBySampleGroupId(state, value) {
  state.hasTooManySamplesInViewportBySampleGroupId = value;
}

export function setIsLayerReorderingNeeded(state, value) {
  state.isLayerReorderingNeeded = value;
}

export function setIsSyncingFigureViewState(state, value) {
  state.isSyncingFigureViewState = value;
}
