export const ESPG_4269 = 'EPSG:4269';

export const projectionConfigs = [
  {
    code: ESPG_4269,
    name: 'NAD83',
    bbox: [86.45, 167.65, 14.92, -40.73],
    proj4Def:
      '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs',
  },
];
