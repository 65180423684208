<script setup lang="ts">
import useDate from '@/js/composables/useDate';
import { useStore } from '@/js/store';
import { Sample } from '@component-library/enviro';
import { parseIdAsArray } from '@component-library/business-logic/app';
import type { App } from '@component-library/gather';
import { InputValue, SectionWithLoading } from '@component-library/gather';
import { Project } from '@component-library/project';
import { computed, inject, PropType } from 'vue';
import FieldValueRow from './FieldValueRow.vue';
import SectionHeadingRow from './SectionHeadingRow.vue';

const props = defineProps({
  samples: {
    type: Array as PropType<Sample[]>,
    required: true,
  },
  templateTab: {
    type: Object,
    required: true,
  },
  sections: {
    type: Array as PropType<SectionWithLoading[]>,
    required: true,
  },
  inputValues: {
    type: Array as PropType<InputValue[]>,
    required: true,
  },
  loadingSamples: Boolean,
  editable: Boolean,
});

const openItemPopover = inject<Function>('openItemPopover')!;

const store = useStore();
const project = computed(() => store.state.project! as Project);

const { convertDate } = useDate();

const isCompoundApp = computed(() => {
  return parseIdAsArray(props.templateTab.id).length > 1;
});

function getAppName(appId: number | null, apps: App[]) {
  return appId !== null ? apps.find((app) => app.id === appId)!.title : '';
}
</script>

<template>
  <div
    class="table-responsive default-styling sticky-table gather-table all-data-table"
    style="isolation: isolate"
  >
    <table class="table data-table table-fixed">
      <thead>
        <tr class="heading-row">
          <th class="sample-id-cell" :colspan="editable ? 2 : 1">Item Title</th>
          <th
            v-for="sample in samples"
            :key="sample.id"
            @click="openItemPopover(sample)"
            :title="sample.custom_title"
          >
            {{ sample.custom_title }}
          </th>
        </tr>

        <!--Used for combined samples-->
        <tr v-if="isCompoundApp" class="heading-row">
          <th :colspan="editable ? 2 : 1">App Name</th>
          <th v-for="sample in samples" :key="`appName-${sample.id}`">
            <span>
              {{ getAppName(sample.template_tab_id, templateTab.sources) }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="project.company_id == 82">
          <tr>
            <td class="identifier-column-sticky heading-title-column">
              Reference Number
            </td>
            <td class="heading-title-column"></td>
            <td
              v-for="sample in samples"
              :key="sample.id"
              @click="openItemPopover(sample)"
            >
              {{ sample.id }}
            </td>
          </tr>
          <tr>
            <td class="identifier-column-sticky heading-title-column">
              Submission Date
            </td>
            <td class="heading-title-column"></td>
            <td
              v-for="sample in samples"
              :key="sample.id"
              @click="openItemPopover(sample)"
            >
              {{ convertDate(sample.created_at) }}
            </td>
          </tr>
        </template>
        <template v-for="section in sections">
          <SectionHeadingRow
            :section="section"
            @toggleSection="(section) => $emit('toggleSection', section)"
            :numberOfSamples="samples.length"
          />
          <template v-if="section.toggled">
            <template v-for="sectionIndex in section.max_section_index">
              <FieldValueRow
                v-for="field in section.template_fields"
                :samples="samples"
                :inputValues="inputValues"
                :templateTab="templateTab"
                :section="section"
                :field="field"
                :sectionIndex="sectionIndex - 1"
                :editable="editable"
                :loadingSamples="loadingSamples"
                :key="sectionIndex + '-field-' + section.id + '-' + field.id"
                :class="{
                  'bg-light': sectionIndex % 2 == 0,
                  'primary-field-title': section.primary_field_id == field.id,
                }"
                @selectField="
                  (sample) =>
                    $emit('selectField', {
                      sample,
                      field_id: field.id,
                      section_index: sectionIndex,
                    })
                "
                @loadSamples="(data) => $emit('loadSamples', data)"
                @viewImage="(data) => $emit('viewImage', data)"
                @viewMedia="(data) => $emit('viewMedia', data)"
              />
            </template>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.primary-field-title {
  background: #3366a260;
}

.heading-row th {
  text-align: center;
}

.sample-id-column {
  height: 64px;
  text-align: left;
}

.sticky-table {
  min-height: auto;
}

.field-column {
  min-width: 160px !important;
  width: 160px !important;
}
</style>
