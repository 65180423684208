<template>
  <DropdownMenu class="filter">
    <template slot="button" slot-scope="btn">
      <div
        @click="loadValues(btn)"
        style="white-space: unset"
        :class="{
          clickable: canBeFiltered,
        }"
      >
        <slot></slot>
      </div>
    </template>
    <template slot="items" slot-scope="items">
      <template v-if="items.toggled">
        <Spinner small v-if="loading" />
        <template v-else>
          <h6 class="mb-2">Filter by</h6>

          <template v-if="isSearchFilter">
            <input
              class="form-control form-control-sm"
              :type="getSearchFilterDataType"
              placeholder="specific value..."
              v-model="specificValue"
            />
          </template>
          <div class="filter__values text-wrap" v-else>
            <div
              class="form-check"
              v-for="(value, valueKey) in filterableValues"
              :key="value"
            >
              <input
                type="checkbox"
                class="form-check-input"
                :id="field.id + '-value-' + valueKey"
                v-model="valuesToFilterBy"
                :value="getValue(value)"
              />
              <label
                class="form-label mb-0"
                :for="field.id + '-value-' + valueKey"
              >
                {{ getDisplayValue(value) }}
              </label>
            </div>
          </div>

          <div class="d-flex justify-content-end align-items-center mt-2">
            <button
              class="btn btn-sm btn-light me-2"
              @click="clearFilter"
              v-if="hasAppliedFilter"
            >
              Clear
            </button>

            <ButtonSpinner
              class="btn btn-sm btn-dark"
              :is-loading="loadingSamples"
              :disabled="isApplyDisabled"
              @click.native="addFilter"
            >
              Apply
            </ButtonSpinner>
          </div>
        </template>
      </template>
    </template>
  </DropdownMenu>
</template>

<script>
import { mapState } from 'vuex';
import api from '../api';
import DropdownMenu from '@/js/components/DropdownMenu.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import { canBeFiltered, FieldTypeIds } from '@component-library/fields';
import Spinner from '@component-library/components/Spinner.vue';

export default {
  props: {
    section: {
      type: Object,
      required: false,
    },
    sectionIndex: {
      type: Number,
      required: false,
    },
    field: Object,
    loadingSamples: Boolean,
  },
  data: () => ({
    loading: false,
    valuesToFilterBy: [],
    filterableValues: [],
    specificValue: null,
  }),
  components: {
    DropdownMenu,
    ButtonSpinner,
    Spinner,
  },
  computed: {
    ...mapState({
      fieldValueFilters: (state) => state.gather.filters.fieldValueFilters,
    }),
    hasAppliedFilter() {
      return (
        this.fieldValueFilters.findIndex((f) => f.field_id == this.field.id) !=
        -1
      );
    },
    canBeFiltered() {
      return canBeFiltered(this.field);
    },
    isSearchFilter() {
      return [FieldTypeIds.TEXT, FieldTypeIds.NUMBER].includes(
        this.field.field_type_id
      );
    },
    getSearchFilterDataType() {
      if (FieldTypeIds.TEXT == this.field.field_type_id) {
        return 'text';
      }

      if (FieldTypeIds.NUMBER == this.field.field_type_id) {
        return 'number';
      }
    },
    isApplyDisabled() {
      if (this.isSearchFilter) {
        return !this.specificValue;
      }

      return this.valuesToFilterBy.length == 0 && !this.hasAppliedFilter;
    },
  },
  methods: {
    loadValues(button) {
      if (!this.canBeFiltered) {
        return;
      }

      if (!button.toggled) {
        if (this.filterableValues.length == 0 && !this.isSearchFilter) {
          this.loading = true;

          let params = {
            field_id: this.field.id,
          };

          if (this.section) {
            params = {
              ...params,
              section_id: this.section.id,
              section_index: this.sectionIndex,
            };
          }

          api
            .getFilterableValues(params)
            .then((response) => {
              this.filterableValues = response.data.values;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }

      button.toggle();
    },
    getValue(value) {
      return value.replace(/<[^>]*>/, '');
    },
    getDisplayValue(value) {
      const matchResult = value.match(/<([^>]*)>/);
      return !!matchResult ? matchResult[1] : value;
    },
    clearFilter() {
      this.$store.dispatch('gather/removeFieldValueFilter', this.field.id);
      this.$emit('loadSamples');
    },
    addFilter() {
      this.$store.dispatch('gather/addFieldValueFilter', {
        field_id: this.field.id,
        values: this.isSearchFilter
          ? [this.specificValue]
          : this.valuesToFilterBy,
        is_search: this.isSearchFilter,
      });

      this.$emit('loadSamples');
    },
  },
};
</script>
