import { STROKE_PATTERN_SOLID } from '@component-library/business-model/common';
import { BLACK, WHITE } from '../color';
import { LAYER_TYPES, flattenLayerTree, getLayerTitle } from '../layer';

export const ALIGNMENT_LEFT = 0;
export const ALIGNMENT_CENTER = 1;

export function createImagePlaceholder() {
  return { url: null, isFromGather: false };
}

export const DEFAULT_OPTIONS = Object.freeze({
  text: '',
  image_data: Object.freeze(createImagePlaceholder()),
  mapData: null,
  isTextUsedAsCaption: false,
  // text options
  textColor: BLACK,
  textOutlineColor: WHITE,
  fontSize: 9,
  alignment: ALIGNMENT_LEFT,
  isItalic: false,
  isBold: false,
  isUnderlined: false,
  // box options
  width: 200,
  height: 200,
  color: BLACK, // outline colour
  outlineStyle: STROKE_PATTERN_SOLID - 1,
  weight: 2, // outline thickness
  opacity: 1, // outline opacity
  backgroundColor: WHITE,
  backgroundOpacity: 1,
  isAutoPositioningEnabled: true,
  // connector options
  connectorColor: BLACK,
  connectorPattern: STROKE_PATTERN_SOLID - 1,
  connectorWeight: 2,
  connectorOpacity: 1,
});

export enum Usage {
  ShowText = 1,
  ShowImage = 2,
  ShowMap = 3,
}

export const UsageOptions = [
  { id: Usage.ShowText, label: 'Show text' },
  { id: Usage.ShowImage, label: 'Show image' },
  { id: Usage.ShowMap, label: 'Show map' },
];

export const IMAGE_CALL_OUT_DEFAULT_TEXT = 'Uncaptioned image call-out';
export const MAP_CALL_OUT_DEFAULT_TEXT = 'Uncaptioned map call-out';

export function getUsage(options) {
  const { usage } = options;
  if (usage) {
    return usage;
  }

  const { image_data } = options;
  return image_data.url ? Usage.ShowImage : Usage.ShowText;
}

export type MapData = {
  basemapApiIndex: number;
  // Deprecated but must be retained here for backward-compatibility.
  layerId: number;
  layerIds: number[] | undefined;
};

export function getLayerOptions(layerTree): { label: string; value: string }[] {
  return flattenLayerTree(layerTree)
    .filter((layer) => {
      return [
        LAYER_TYPES.ARROW,
        LAYER_TYPES.CHAINAGE,
        LAYER_TYPES.CIRCLE,
        LAYER_TYPES.FEATURE_COLLECTION,
        LAYER_TYPES.HEDGE,
        LAYER_TYPES.IMAGE,
        LAYER_TYPES.POLYGON,
        LAYER_TYPES.POLYLINE,
        LAYER_TYPES.RECTANGLE,
        LAYER_TYPES.SAMPLE_GROUP,
        LAYER_TYPES.SITE_BOUNDARY,
        LAYER_TYPES.TEXT,
        LAYER_TYPES.BUFFER,
      ].includes(layer.data.properties.type);
    })
    .map((layer) => {
      return {
        label: getLayerTitle(layer),
        value: String(layer.data.id),
      };
    })
    .sort((lo1, lo2) => lo1.label.localeCompare(lo2.label));
}

export function getLayerIds(
  mapData: { layerIds: number[] | undefined; layerId: number } | undefined
): number[] {
  if (!mapData) {
    return [];
  }

  return mapData.layerIds ?? [mapData.layerId];
}
