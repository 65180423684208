import store from '@/js/store';
import { Tile as TileLayer } from 'ol/layer';
import { OSM, XYZ } from 'ol/source';
import * as bl from '../../../business-logic';
import { LAYER_TYPES } from '../../../business-logic/layer';
import { BasemapId } from '../../../lib/olbm/layer/basemap/types';
import { EPSG_3857 } from '../../../lib/olbm/projection/common';
import { NAMESPACE } from '../../../store';
import { OGC_OPENGIS_SERVICE_SUBTYPES } from '../services';
import enableLoadingEvents from '../services/enableLoadingEvents';
import getCachedBasemapOpacity from './getCachedBasemapOpacity';
import { assignIdToLayer } from './LayerManager';

export default function createBasemapServiceLayer(
  map,
  basemapApi,
  maxZoom,
  opacity = getCachedBasemapOpacity()
) {
  let layer;

  const {
    GOOGLE_MAPS_ROADMAP,
    AU_VIC_CARTOGRAPHIC,
    AU_VIC_OVERLAY,
    AU_VIC_AERIAL,
    AU_NSW_AERIAL,
    AU_QLD_AERIAL,
    AU_WA_AERIAL,
    AU_SA_AERIAL,
    OPEN_STREET_MAP,
    GB_BRISTOL_AND_SURROUNDING_AREA,
    GB_OS_MAPS_ROAD,
    GB_OS_VECTOR,
    CA_TOPOGRAPHIC,
    GOOGLE_MAPS_SATELLITE,
  } = BasemapId;
  const integrations = NAMESPACE.getState(store, 'integrations');
  basemapApi = bl.basemap.preprocessBasemapApi(basemapApi, integrations);
  if ([GOOGLE_MAPS_ROADMAP, GOOGLE_MAPS_SATELLITE].includes(basemapApi.index)) {
    layer = map.layerManager.createGoogleMapsLayer(basemapApi.options);
  } else if (
    [AU_VIC_CARTOGRAPHIC, AU_VIC_OVERLAY, AU_VIC_AERIAL].includes(
      basemapApi.index
    )
  ) {
    layer = map.layerManager.createOgcOpenGisLayer(
      OGC_OPENGIS_SERVICE_SUBTYPES.WMTS,
      basemapApi.options
    );
  } else if (
    [AU_NSW_AERIAL, GB_BRISTOL_AND_SURROUNDING_AREA].includes(basemapApi.index)
  ) {
    layer = map.layerManager.createEsriLayer(
      'dynamicMapLayer',
      basemapApi.options
    );
  } else if ([AU_QLD_AERIAL, AU_WA_AERIAL].includes(basemapApi.index)) {
    layer = map.layerManager.createEsriLayer(
      'imageMapLayer',
      basemapApi.options
    );
  } else if ([AU_SA_AERIAL, GB_OS_MAPS_ROAD].includes(basemapApi.index)) {
    layer = map.layerManager.createOgcOpenGisLayer(
      OGC_OPENGIS_SERVICE_SUBTYPES.WMTS,
      basemapApi.options
    );
  } else if ([CA_TOPOGRAPHIC, GB_OS_VECTOR].includes(basemapApi.index)) {
    layer = map.layerManager.createEsriLayer(
      'vectorTileLayer',
      basemapApi.options
    );
  } else {
    const SourceType = basemapApi.index === OPEN_STREET_MAP ? OSM : XYZ;
    const getMaxZoom = () => {
      if (typeof maxZoom === 'number') {
        return maxZoom;
      }

      if (typeof basemapApi.options.maxNativeZoom === 'number') {
        return basemapApi.options.maxNativeZoom;
      }

      return 24;
    };
    const projection = basemapApi.options.projection ?? EPSG_3857;
    const source = new SourceType({
      maxZoom: getMaxZoom(),
      crossOrigin: 'anonymous',
      url: basemapApi.source,
      attributions: [basemapApi.options.attributions],
      projection,
    });

    // 09/01/24 This causes huge performance issues when switching basemaps
    // Maybe it doesn't get cleaned up when the basemap is removed. Not sure.
    // 16/02/24 This line is required to find the right resolution. It's uncommented
    // and let's keep an eye on it.
    map.patchTileGrid(source.getTileGrid(), projection);

    layer = new TileLayer({
      opacity,
      source,
    });
  }

  assignIdToLayer(layer);
  layer.options = {
    ...layer.options,
    type: LAYER_TYPES.BASEMAP_SERVICE,
    index: basemapApi.index,
  };

  enableLoadingEvents(layer);

  return layer;
}
