<template>
  <div
    class="modal modal-mask fade"
    :class="{ show: visible }"
    @click="handleShadeClick"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      :class="{
        'full-modal': full,
        'modal-lg': half,
        'modal-sm': small,
        'modal-three-quarter': threeQuarter,
        'full-height': stretch,
        'modal-dialog-scrollable': scrollable,
      }"
    >
      <div
        class="modal-content"
        :class="isLoading ? 'flex justify-content-center' : ''"
        @click.stop
      >
        <div v-if="isLoading" class="text-center py-4">
          <Spinner large />
        </div>
        <template v-else>
          <div
            class="modal-header d-flex align-items-center"
            v-if="!notClosable && !hideHeader"
          >
            <div class="modal-title">
              <slot name="header" />
            </div>
            <button type="button" class="btn-close" @click="close"></button>
          </div>
          <slot></slot>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@component-library/components/Spinner.vue';

/**
 * @deprecated Use component-library/components/Modal.vue
 */
export default {
  props: {
    show: Boolean,
    full: Boolean,
    half: Boolean,
    threeQuarter: Boolean,
    small: Boolean,
    isLoading: Boolean,
    notClosable: Boolean,
    hideHeader: Boolean,
    stretch: Boolean,
    scrollable: Boolean,
    shouldCloseOnShadeClick: {
      type: Boolean,
      default: true,
    },
  },
  components: { Spinner },
  data: () => ({
    visible: false,
  }),
  watch: {
    show() {
      this.setVisible();
    },
  },
  methods: {
    close() {
      if (this.isLoading) {
        return;
      }

      this.$emit('close');
    },
    async setVisible() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.visible = this.show;
          resolve();
        }, 150);
      });
      await this.$nextTick();
      this.$emit('visibleChanged', this.visible);
    },
    handleShadeClick() {
      if (this.notClosable || !this.shouldCloseOnShadeClick) {
        return;
      }

      this.close();
    },
  },
  mounted() {
    console.warn('Deprecated: Use component-library/components/Modal.vue');

    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode == 27) {
        this.close();
      }
    });

    this.setVisible();
  },
};
</script>
